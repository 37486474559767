var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "snippetVisualisation",
      staticClass: "snippet-visualisation preview-border-normal",
      class: { "zoom-cursor": _vm.isHovering },
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isHovering,
                  expression: "isHovering"
                }
              ],
              staticClass: "btn-copy",
              attrs: {
                type: "is-primary",
                size: "is-small",
                rounded: "",
                "icon-left": "content-copy"
              },
              on: { click: _vm.copyContent }
            },
            [_vm._v("Copy")]
          )
        ],
        1
      ),
      _c("div", {
        staticClass: "snippet-hover-scrim",
        on: {
          click: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.openDetail.apply(null, arguments)
          }
        }
      }),
      _c("InfoHover", {
        attrs: {
          "is-hovering": _vm.isHovering,
          references: _vm.snippet.references,
          "total-references": _vm.snippet.references.length
        }
      }),
      _c("h1", { domProps: { innerHTML: _vm._s(_vm.highlightedTitle) } }),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.highlightedContent) } }),
      _c(
        "b-modal",
        {
          attrs: { "trap-focus": "" },
          on: { close: _vm.hideDetail },
          model: {
            value: _vm.showDetail,
            callback: function($$v) {
              _vm.showDetail = $$v
            },
            expression: "showDetail"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-container pa-5",
              class: { "scrollbar-visible": _vm.scrolling },
              on: { scroll: _vm.handleScrolling }
            },
            [
              _c("div", { staticClass: "snippet-content" }, [
                _c("h1", [_vm._v(_vm._s(_vm.snippet.title))]),
                _c("p", [_vm._v(_vm._s(_vm.snippet.content))])
              ]),
              _c("div", { staticClass: "modal-subheader" }, [
                _vm._v(
                  " Appears in " +
                    _vm._s(_vm.snippet.references.length) +
                    " " +
                    _vm._s(
                      _vm.snippet.references.length === 1
                        ? "document"
                        : "documents"
                    ) +
                    " "
                )
              ]),
              _vm._l(_vm.snippet.references, function(reference, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "reference mt-3" },
                    [
                      _c("DocumentTag", {
                        staticClass: "u-column is-narrow mr-3",
                        attrs: {
                          mimetype: reference.resource.integrationfile.mimetype
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: _vm.getUrl(reference),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(reference.resource.title))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "integration-container" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: reference.resource.integrationfile.folder_url
                          }
                        },
                        [
                          _c("IntegrationLink", {
                            staticClass: "mr-2",
                            attrs: {
                              hover: true,
                              integration:
                                reference.resource.integrationfile.integration,
                              "integration-path":
                                reference.resource.integrationfile.path
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              reference.resource.integrationfile
                                .external_modified_by
                                ? " Last modified by"
                                : " Created by"
                            ) +
                            " " +
                            _vm._s(_vm.showFullName(reference.resource)) +
                            " on " +
                            _vm._s(_vm.showDate(reference.resource)) +
                            " "
                        )
                      ]),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            label: reference.resource.is_public
                              ? "Public"
                              : "Synced permissions",
                            type: "is-white",
                            position: "is-right"
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "body body-text is-narrow",
                            attrs: {
                              size: "is-small",
                              icon: reference.resource.is_public
                                ? "earth"
                                : "account-multiple"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }