<template>
  <div class="btn" @click="handleClick()">
    <div class="btn-icon">
      <div class="btn-icon-row-a">
        <div :class="{ 'btn-icon-row-a-front': true, active: active }"></div>
        <div class="btn-icon-row-a-mid"></div>
        <div :class="{ 'btn-icon-row-a-back': true, active: active }"></div>
      </div>
      <div class="btn-icon-row-b">
        <div :class="{ 'btn-icon-row-b-front': true, active: active }"></div>
        <div class="btn-icon-row-b-mid"></div>
        <div :class="{ 'btn-icon-row-b-back': true, active: active }"></div>
      </div>
      <div class="btn-icon-row-a">
        <div :class="{ 'btn-icon-row-a-front': true, active: active }"></div>
        <div class="btn-icon-row-a-mid"></div>
        <div :class="{ 'btn-icon-row-a-back': true, active: active }"></div>
      </div>
    </div>
    <span class="btn-text">Filters</span>
  </div>
</template>

<script>
export default {
  name: 'AdvancedFiltersButton',
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('toggle', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0 0.8rem;
  height: 2rem;
  background: white;
  cursor: pointer;
  user-select: none;

  &-icon {
    width: 1rem;
    height: 1rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    gap: 1px;

    &-row {
      &-a {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &-front {
          width: 60%;
          border-top: 1px solid #60666b;
          transition: width ease 200ms;

          &.active {
            width: 15%;
          }
        }

        &-mid {
          width: 15%;
          height: 100%;
          border-left: 1px solid #60666b;
          transition: width ease 200ms;
        }

        &-back {
          width: 25%;
          border-top: 1px solid #60666b;
          transition: width ease 200ms;

          &.active {
            width: 70%;
          }
        }
      }

      &-b {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &-front {
          width: 20%;
          border-top: 1px solid #60666b;
          transition: width ease 200ms;

          &.active {
            width: 65%;
          }
        }

        &-mid {
          width: 15%;
          height: 100%;
          border-left: 1px solid #60666b;
          transition: width ease 200ms;
        }

        &-back {
          width: 65%;
          border-top: 1px solid #60666b;
          transition: width ease 200ms;
          &.active {
            width: 20%;
          }
        }
      }
    }
  }

  &-text {
    color: #60666b;
  }
}
</style>
