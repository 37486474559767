var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-list-item mb-4" },
    [
      _c("div", { class: { "hover-marker": true, active: _vm.hover } }),
      _c(
        "ResourceCardBase",
        {
          staticClass: "pb-1 resource-list-item",
          class: {
            "dense-text": _vm.isDense
          },
          attrs: {
            source: _vm.resourceIntegration
              ? _vm.resourceIntegration.integration
              : undefined
          },
          nativeOn: {
            mouseover: function($event) {
              _vm.hover = true
            },
            mouseleave: function($event) {
              _vm.hover = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "title-container" },
                    [
                      _vm.mimetype
                        ? _c("DocumentTag", {
                            staticClass: "title-container-tag",
                            attrs: { mimetype: _vm.blobMimetype }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "title-container-header" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "title-container-header-title-wrapper"
                            },
                            [
                              _c("HighlightedTextComponent", {
                                staticClass: "title-container-header-title",
                                attrs: {
                                  "split-text": _vm.title,
                                  "base-class": {
                                    blue: true,
                                    header: true,
                                    xs: true,
                                    "mb-2": true,
                                    "cursor-pointer": true,
                                    "no-select": true,
                                    bold: true
                                  },
                                  "custom-highlight": {
                                    "font-weight": 900,
                                    background: "rgba(255, 184, 0, 0.2)",
                                    "border-radius": "4px",
                                    padding: "0 4px"
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onTitleClick.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.highlightsEnabled &&
                          (_vm.canWriteLabels || _vm.highlightValue)
                            ? _c(
                                "div",
                                {
                                  staticClass: "title-container-highlight",
                                  class: { clickable: _vm.canWriteLabels },
                                  on: {
                                    mouseenter: function($event) {
                                      _vm.highlightHovered = true
                                    },
                                    mouseleave: function($event) {
                                      _vm.highlightHovered = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "title-container-highlight-icon",
                                    attrs: {
                                      src: require("@/assets/icons/star" +
                                        (_vm.highlightValue ? "-filled" : "") +
                                        ".svg"),
                                      alt: ""
                                    },
                                    on: { click: _vm.toggleHighlight }
                                  }),
                                  _vm.highlightValue
                                    ? _c("HighlightHoverInfo", {
                                        attrs: {
                                          highlight: _vm.highlightValue,
                                          hovered: _vm.highlightHovered
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("QualityLabels", {
                            attrs: {
                              "active-label": _vm.label,
                              "source-id": _vm.uuid,
                              "small-labels": true,
                              "bordered-icons": false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "ResourceInfo",
                        _vm._g(
                          {
                            staticClass: "title-container-info",
                            attrs: {
                              "resource-integration": _vm.resourceIntegration,
                              "no-integration-path-tooltip":
                                _vm.noIntegrationPathTooltip,
                              hover: _vm.hover
                            },
                            on: { "on-path-click": _vm.onPathClick }
                          },
                          _vm.$listeners
                        )
                      ),
                      _c(
                        "div",
                        { staticClass: "title-container-buttons" },
                        [
                          _c("Button", {
                            attrs: {
                              text: "AI chat",
                              icon: "chat",
                              type: "light-solid",
                              "icon-left": true
                            },
                            on: {
                              click: function($event) {
                                return _vm.showResourceModal(
                                  1,
                                  "question-answer"
                                )
                              }
                            }
                          }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.isBookmarked
                                  ? "Remove bookmark"
                                  : "Add bookmark",
                                position: "is-top",
                                type: "is-dark"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "title-container-bookmark" },
                                [
                                  _c("img", {
                                    staticClass:
                                      "title-container-bookmark-icon",
                                    attrs: {
                                      src: require("@/assets/icons/bookmark" +
                                        (_vm.isBookmarked ? "-filled" : "") +
                                        ".svg"),
                                      alt: ""
                                    },
                                    on: { click: _vm.toggleBookmark }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm.downloadFilesEnabled
                            ? _c("UploadDownloadDropdown", {
                                attrs: {
                                  "props-call": _vm.getDocumentDownloadProps
                                }
                              })
                            : _vm._e(),
                          _vm.isDownloadableMimetype && _vm.showCollectSlides
                            ? _c("ResourceCollectMenu", {
                                attrs: {
                                  "resource-id": _vm.uuid,
                                  "resource-trace-id": _vm.traceId
                                }
                              })
                            : _vm._e(),
                          _c("ResourceAssistMenu", {
                            attrs: { "similar-pages": _vm.similarPages },
                            on: {
                              openSimilar: function($event) {
                                return _vm.showResourceModal(1, "similar")
                              },
                              openSummarize: function($event) {
                                return _vm.showResourceModal(1, "summarize")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "tag-container" },
                    [
                      _c("MissingWords", {
                        attrs: {
                          "missing-words": _vm.missingWords,
                          "resource-trace-id": _vm.traceId
                        }
                      }),
                      _vm.missingWords.length > 0 &&
                      _vm.aggregatedTags.length > 0
                        ? _c("div", { staticClass: "splitter" })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: {
                            "resource-tag-container": true,
                            "pv-3": true,
                            "rli-title-mobile": _vm.isMobile
                          }
                        },
                        [
                          _c("ResourceTagList", {
                            attrs: {
                              tags: _vm.aggregatedTags,
                              activity: _vm.activity
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mb-3 mv-3" })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "mv-2" }, [
            _c(
              "div",
              {
                staticClass: "columns no-margin is-mobile is-vcentered",
                staticStyle: { "align-items": "stretch" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "u-column",
                    staticStyle: { "max-width": "100%" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "u-columns is-vertical space-between full-height",
                        staticStyle: { "flex-direction": "column" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "u-column is-narrow no-padding" },
                          [
                            !_vm.isDense &&
                            _vm.description &&
                            _vm.description.length > 0
                              ? _c("HighlightedTextComponent", {
                                  attrs: {
                                    "split-text": _vm.description,
                                    "base-class": {
                                      body: true,
                                      "mb-4": !_vm.isDense,
                                      description: true,
                                      "no-select": true
                                    },
                                    "custom-highlight": {
                                      "font-weight": 900,
                                      background: "rgba(255, 184, 0, 0.2)",
                                      "border-radius": "4px",
                                      padding: "0 4px"
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.showSubresources
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "250px",
                                      "max-width": "1250px"
                                    }
                                  },
                                  [
                                    _c("SubResourceList", {
                                      attrs: {
                                        subresources: _vm.relevantSubresources,
                                        "margin-between": 4,
                                        mimetype: _vm.blobMimetype,
                                        "show-page-button": !_vm.thumbnailFallback,
                                        "resource-id": _vm.uuid,
                                        "resource-trace-id": _vm.traceId,
                                        "resource-type": _vm.resourceType
                                      },
                                      on: {
                                        cantLoad: function($event) {
                                          _vm.hideSubresources = true
                                        },
                                        slideClicked: _vm.onSlideClick
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }