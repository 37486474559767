var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-loading-container u-columns is-multiline" },
    _vm._l(2, function(index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "\n      pb-loading-user\n      u-column\n      is-12-desktop is-12-widescreen is-12-fullhd is-12-tablet is-12-mobile\n      ph-5\n      pv-2\n    "
        },
        [
          _c(
            "div",
            { staticClass: "pb-loading-img" },
            [
              _c("b-skeleton", {
                attrs: { circle: "", width: "50px", height: "50px" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pb-loading-info" },
            [
              _c("b-skeleton", { attrs: { width: "80%", height: "100%" } }),
              _c("b-skeleton", { attrs: { width: "70%", height: "100%" } })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }