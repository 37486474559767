var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchResults", {
        attrs: {
          "default-type": _vm.landingType || _vm.foundType,
          "search-query": _vm.searchQuery,
          "results-resources": _vm.resourceResults || [],
          "amount-results-resources": _vm.paginatorDataCount,
          "loading-resources": _vm.resourcesLoading,
          "loading-filters": _vm.settingUpPaginator,
          workspaces: _vm.populatedWorkspaces,
          "all-loaded": _vm.isDone,
          "current-user": _vm.currentUser,
          "loading-secondary-data": _vm.settingUpPaginator,
          "filter-options": _vm.filterBlocks,
          "active-document-type": [],
          "resource-types": _vm.facets.resourceTypes,
          "has-secondary": true,
          sort: _vm.secondaryFilters.currentSortType,
          "total-resources": _vm.totalResourceAmount,
          "extracted-entities": _vm.facets.extractedEntities,
          "loading-error": _vm.loadingError,
          "top-filters": [],
          "search-request-props": _vm.searchRequestProps,
          types: _vm.aggregations ? _vm.aggregations.types || {} : {}
        },
        on: {
          filterClicked: function(x) {
            return _vm.activateFilter(x)
          },
          optionClicked: function(option) {
            return _vm.activateFilter(option)
          },
          clearFilters: _vm.clearFilters,
          clearFiltersByType: _vm.clearFiltersByType,
          saveFilters: function() {
            return _vm.setFilterInfo()
          },
          sortingChanged: function(x) {
            return (_vm.secondaryFilters.currentSortType = x)
          },
          loadMoreResources: _vm.loadMoreResources,
          topicChanged: _vm.onTopicChanged
        },
        scopedSlots: _vm._u([
          {
            key: "top-blocks",
            fn: function() {
              return [
                _c("PeopleBlock", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchQuery,
                      expression: "searchQuery"
                    }
                  ],
                  staticClass: "mt-4 mb-5",
                  attrs: { members: _vm.members, loading: _vm.resourcesLoading }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.showCollectSlides ? _c("CustomSlideCreator") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }