<template>
  <div class="filters" :style="secondaryHeightStyle">
    <PillFilterBlock v-if="!searchErrored" class="mb-4" />
    <div
      v-if="!(searchEmpty || searchErrored)"
      id="filters-primary"
      class="filters-primary"
    >
      <div class="u-column is-narrow">
        <span v-if="!resultAmountLoading && resultAmount >= 0"
          >Showing {{ resultAmount }} results
          <span v-if="query">for '{{ query }}'</span></span
        >
      </div>
      <div class="filters-primary-showmore">
        <AdvancedFiltersButton
          id="filterdropdown"
          :active="showSecondaryFilters"
          @toggle="showMoreFilters()"
        />
      </div>
    </div>
    <Transition
      v-if="!(searchEmpty || searchErrored)"
      name="popout"
      :duration="300"
    >
      <div
        v-show="showSecondaryFilters"
        :class="{
          'filters-secondary': true,
          'filters-secondary-shown': secondaryFiltersShown
        }"
      >
        <HorizontalFilters
          class="pb-4 horizontal-filters"
          :top-filters="advancedFilters"
          :filters-loading="filtersLoading"
          v-on="$listeners"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import HorizontalFilters from '@c/shared/molecules/filters/HorizontalFilters.vue'
import AdvancedFiltersButton from './AdvancedFiltersButton.vue'
import PillFilterBlock from './PillFilterBlock.vue'

export default {
  name: 'SearchHeader',
  components: { AdvancedFiltersButton, HorizontalFilters, PillFilterBlock },
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    extractedEntities: {
      type: Object,
      default: () => {}
    },
    filtersLoading: {
      type: Boolean,
      required: true
    },
    resultAmount: {
      type: Number,
      required: true
    },
    resultAmountLoading: {
      type: Boolean,
      required: true
    },
    searchEmpty: {
      type: Boolean,
      default: false
    },
    searchErrored: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showSecondaryFilters: false,
    secondaryFiltersShown: false,
    secondaryHeightStyle: ''
  }),
  computed: {
    query() {
      return this.$route?.query?.query
    },
    advancedFilters() {
      const toRemove = ['bookmarks', 'editors']
      return this.filterOptions.filter(
        (el) => !toRemove.includes(el.type) && el.options.length > 0
      )
    }
  },
  mounted() {
    this.estimateFilterRows()
    window.addEventListener('resize', this.estimateFilterRows)
  },
  methods: {
    showMoreFilters() {
      this.showSecondaryFilters = !this.showSecondaryFilters
      setTimeout(
        () => (this.secondaryFiltersShown = !this.secondaryFiltersShown),
        this.secondaryFiltersShown ? 0 : 300
      )
    },
    estimateFilterRows() {
      const containerWidth =
        (document.getElementById('filters-primary')?.getBoundingClientRect()
          ?.width || 0) * 0.85
      const rowsEstimate =
        Math.ceil(10 / Math.floor(containerWidth / 150 || 0)) || 10
      const bottomPadding = 16
      const maxHeightEstimate =
        rowsEstimate * 34 + (rowsEstimate - 1) * 8 + bottomPadding
      this.secondaryHeightStyle = `--secondary-filters-height: ${maxHeightEstimate}px`
    },
    getSpanClass(type) {
      if (type.toLowerCase() === 'organisation') return 'span-orange'
      if (type.toLowerCase() === 'source') return 'span-purple'
      if (type.toLowerCase() === 'topic') return 'span-green'
      if (type.toLowerCase() === 'content class') return 'span-blue'
      if (type.toLowerCase() === 'industry') return 'span-red'
    },
    getSpanTextColor(type) {
      if (type.toLowerCase() === 'organisation') return 'text-orange'
      if (type.toLowerCase() === 'source') return 'text-purple'
      if (type.toLowerCase() === 'topic') return 'text-green'
      if (type.toLowerCase() === 'content class') return 'text-blue'
      if (type.toLowerCase() === 'industry') return 'text-red'
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style scoped lang="scss">
.popout-enter-active,
.popout-leave-active {
  transition: max-height 300ms;
  overflow: hidden;
}
.popout-enter-active {
  max-height: var(--secondary-filters-height);
}
.popout-enter,
.popout-leave-active {
  max-height: 0;
}
.popout-leave {
  max-height: var(--secondary-filters-height);
}

.filters {
  width: 100%;
  &-primary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    height: 2rem;
    margin-bottom: 0.5rem;

    &-filters {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      height: 2rem;
      align-items: center;
      overflow: hidden;
    }

    &-divider {
      height: 75%;
      width: 3px;
      border-radius: 999rem;
      background: #e9ebed;
    }

    &-showmore {
      width: 18ch;
    }
  }

  &-secondary {
    width: 100%;
    max-width: 100%;
    position: relative;
    &-shown {
      max-height: var(--secondary-filters-height);
    }
  }
}

.text-orange {
  color: rgb(252, 174, 79, 1);
}

.text-green {
  color: rgb(166, 189, 148, 1);
}
.text-purple {
  color: rgb(216, 155, 253, 1);
}

.text-blue {
  color: rgb(145, 180, 253, 1);
}

.text-red {
  color: rgb(254, 154, 160, 1);
}
.tooltip-span {
  border-radius: 5px;
  padding: 0 5px;
  cursor: default;
  transition: all 0.3s ease;
}

.span-red {
  background-color: rgb(254, 154, 160, 0.24);
  &:hover {
    background-color: rgb(254, 154, 160, 0.4);
  }
}

.span-blue {
  background-color: rgb(145, 180, 253, 0.24);
  &:hover {
    background-color: rgb(145, 180, 253, 0.4);
  }
}

.span-orange {
  background-color: rgb(252, 174, 79, 0.24);
  &:hover {
    background-color: rgb(252, 174, 79, 0.4);
  }
}

.span-green {
  background-color: rgb(166, 189, 148, 0.24);
  &:hover {
    background-color: rgb(166, 189, 148, 0.4);
  }
}

.span-purple {
  background-color: rgb(216, 155, 253, 0.24);
  &:hover {
    background-color: rgb(216, 155, 253, 0.4);
  }
}

.custom-tooltip ::v-deep .tooltip-content {
  bottom: calc(100% + 3px);
  &::before {
    display: none;
  }
}

.horizontal-filters {
  max-width: 85%;
}
</style>
