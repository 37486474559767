var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "discover", attrs: { id: "sheetsblock" } }, [
    _c(
      "p",
      {
        staticClass: "header semi-bold xs mb-4 row",
        attrs: { id: "sheetsblocktitle" }
      },
      [
        _c("img", {
          staticClass: "header-img mr-3",
          attrs: { src: require("@/assets/icons/sheet.svg"), alt: "" }
        }),
        _vm._v(" " + _vm._s(_vm.header) + " ")
      ]
    ),
    !_vm.allImagesLoaded
      ? _c(
          "div",
          { staticClass: "discover-loading row full-width" },
          _vm._l(3, function(el) {
            return _c("b-skeleton", { key: el, attrs: { height: "100%" } })
          }),
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "discover-sheets",
        class: {
          "discover-sheets-grid":
            _vm.sheetsData && _vm.sheetsData.length === _vm.pageSize
        }
      },
      _vm._l(_vm.sheetsData, function(sheet, idx) {
        return _c(
          "div",
          { key: sheet.uuid, staticClass: "discover-sheets-item" },
          [
            _c("ResourceCard", {
              class: { hidden: !_vm.allImagesLoaded },
              attrs: {
                resource: sheet,
                rank: idx + 1,
                location: "sheetsblock",
                "search-id": _vm.searchId
              },
              on: {
                load: function($event) {
                  _vm.imagesLoaded += 1
                },
                cantLoad: function($event) {
                  _vm.imagesErrored += 1
                }
              }
            })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "discover-more link mt-4",
        on: { click: _vm.showMoreSheets }
      },
      [
        !_vm.sheetsData.length || _vm.sheetsData.length === _vm.pageSize
          ? _c("p", { staticClass: "link blue" }, [
              _vm._v(" See more "),
              _c("span", { staticClass: "link bold" }, [_vm._v("sheets")])
            ])
          : _c("p", { staticClass: "link blue" }, [
              _vm._v(" Go to the "),
              _c("span", { staticClass: "link bold" }, [_vm._v("sheets tab")])
            ]),
        _c("img", {
          staticClass: "discover-more-img",
          attrs: { src: require("@/assets/icons/arrow-left.svg"), alt: "" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }