


































































import Resource, { VISUALISATION_TYPES_ENUM } from '@c/models/Resource'
import User from '@c/models/User'
import Workspace from '@c/models/Workspace'
import SearchEmptyState from '@c/shared/molecules/empty-states/SearchEmptyState.vue'
import SearchErrorState from '@c/shared/molecules/errorStates/SearchErrorState.vue'
import PageWithDoubleSidebar from '@c/shared/molecules/structural/blocks/PageWithDoubleSidebar.vue'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResourceGrid from '../../../shared/molecules/object-visualisations/resource/ResourceGrid.vue'
import { FilterBlockDefinition } from '../interface'
import SearchHeader from '../molecules/SearchHeader.vue'

export default Vue.extend({
  components: {
    SearchEmptyState,
    ResourceGrid,
    PageWithDoubleSidebar,
    SearchErrorState,
    SearchHeader
  },

  props: {
    allLoaded: {
      type: Boolean,
      default: false
    },

    searchQuery: {
      type: String,
      default: ''
    },

    resultsResources: {
      type: Array as () => Resource[],
      default: function (): Resource[] {
        return []
      }
    },

    amountResultsResources: {
      type: Number as () => number,
      default: -1
    },

    loadingResources: {
      type: Boolean,
      default: false
    },

    sort: {
      type: String,
      required: true
    },

    workspaces: {
      type: Array as () => Workspace[],
      default: function (): Workspace[] {
        return []
      }
    },

    currentUser: {
      type: Object as () => User,
      required: true
    },

    loadingSecondaryData: {
      type: Boolean,
      default: false
    },

    loadingFilters: {
      type: Boolean,
      default: false
    },

    resourceTypes: {
      type: Object,
      default: () => ({})
    },

    totalResources: {
      type: Number,
      default: 0
    },

    filterOptions: {
      type: Array as () => FilterBlockDefinition[],
      default: () => []
    },

    extractedEntities: {
      type: Object,
      default: () => {}
    },
    loadingError: {
      type: Boolean,
      default: false
    },
    topFilters: {
      type: Array,
      default: () => []
    },
    searchRequestProps: {
      type: Object,
      default: () => {}
    },
    types: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.webapp.isMobile
    }),
    ...mapGetters(['resourceTypeFilter']),
    viewType() {
      if (this.$route.params?.tab === 'videos')
        return VISUALISATION_TYPES_ENUM.VIDEO
      if (this.resourceTypeFilter === 'slides')
        return VISUALISATION_TYPES_ENUM.SLIDE
      if (this.resourceTypeFilter === 'snippets')
        return VISUALISATION_TYPES_ENUM.SNIPPET
      if (this.resourceTypeFilter === 'people')
        return VISUALISATION_TYPES_ENUM.USER_CARD
      return VISUALISATION_TYPES_ENUM.LIST
    },
    searchEmpty() {
      return (
        !this.loadingResources &&
        this.resultsResources.length === 0 &&
        !this.loadingError
      )
    },
    searchErrored() {
      return !this.loadingResources && this.loadingError
    }
  },
  watch: {
    searchEmpty(newVal) {
      this.setSearchEmpty(newVal)
    },
    searchErrored(newVal) {
      this.setSearchErrored(newVal)
    }
  },
  mounted() {
    this.setSearchEmpty(this.searchEmpty)
    this.setSearchErrored(this.searchErrored)
  },
  methods: {
    ...mapActions(['setSearchEmpty', 'setSearchErrored']),
    floor(x: number) {
      return Math.floor(x)
    },

    ceil(x: number) {
      return Math.ceil(x)
    }
  }
})
