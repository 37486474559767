<template>
  <div id="topfiltersuggestions" class="pills-container">
    <div
      id="pills-scroll"
      :class="{ 'pills-content': true, scroll: scrollEnabled }"
      @scroll="handleScroll()"
    >
      <PillFilter
        v-for="filter in activeFilters"
        :key="filter.title"
        :name="filter.title"
        :query="filter.query"
        class="pill"
        @click.native="handleFilterClicked(filter.query)"
      />
      <PillFilter
        v-for="filter in topFilters"
        :key="filter.title"
        :name="filter.title"
        :query="filter.query"
        class="pill"
        @click.native="handleFilterClicked(filter.query)"
      />
    </div>
    <div v-if="scrollEnabled && arrowVisible.left" class="pills-arrow left">
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="pills-arrow-img"
        @click="scroll(-1)"
      />
    </div>
    <div v-if="scrollEnabled && arrowVisible.right" class="pills-arrow right">
      <img
        src="@/assets/icons/arrow-left.svg"
        class="pills-arrow-img"
        @click="scroll(1)"
      />
    </div>
  </div>
</template>

<script>
import {
  searchDetail,
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { filterFromRoute, routeSafe } from '@/util'
import { isEmpty, orderBy } from 'lodash'
import { mapActions, mapState } from 'vuex'
import PillFilter from './PillFilter.vue'

export default {
  name: 'PillFilterBlock',
  components: { PillFilter },
  data: () => ({
    topFilterFacets: [
      'labels',
      'topics',
      'content_classes',
      'industries',
      'organisations'
    ],
    topFiltersCount: 3,
    minTopFiltersSize: 10,
    scrollEnabled: true,
    arrowVisible: {
      left: true,
      right: true
    },
    lastUpdated: 0
  }),
  computed: {
    ...mapState({
      filterBlocks: (state) => state.filters.filterBlocks,
      filtersLoading: (state) => state.filters.filtersLoading,
      activeFilterOptions: (state) => state.filters.activeFilters,
      extractedEntities: (state) => state.filters.extractedEntities
    }),
    activeFilters() {
      const commonOptions = this.activeFilterOptions.map((f) => ({
        title: f.title || f.key,
        type: f.type,
        query: { [f.type]: f.type === 'authors' ? f.key : f.title || f.key }
      }))
      const viewOptions = this.viewFilters.filter(
        (f) =>
          Object.keys(f.query)[0] === 'view' &&
          (this.$route.query.view || '').includes(f.query.view)
      )
      return [...viewOptions, ...commonOptions]
    },
    totalFilterAmount() {
      return this.filterBlocks
        .filter((block) => this.topFilterFacets.includes(block.type))
        .reduce((prev, block) => prev + block.options.length, 0)
    },
    topFilters() {
      const facetPluralMapping = {
        topic: 'topics',
        content_class: 'content_classes',
        industry: 'industries',
        organisation: 'organisations'
      }
      const actives = this.activeFilters.map((el) => el.title)
      const topFilters = this.filterBlocks.reduce((prev, block) => {
        if (
          this.topFilterFacets.includes(block.type) &&
          !Object.keys(this.extractedEntities || {}).some(
            (key) =>
              facetPluralMapping[this.extractedEntities[key].type] ===
              block.type
          ) &&
          !Object.keys(this.extractedEntities || {}).some(
            (key) =>
              this.extractedEntities[key].type === 'organisation' &&
              block.type === 'industries'
          )
        ) {
          const blockOptions =
            block.type === 'labels'
              ? block.options.filter(
                  (label) =>
                    ['positive', 'informative'].includes(label.labelType) &&
                    label.title !== 'No label'
                )
              : block.options
          return [
            ...prev,
            ...orderBy(blockOptions, 'count', 'desc')
              .slice(0, this.topFiltersCount)
              .map((el) => ({
                title: el.title || el.key,
                type: block.type,
                query: { [block.type]: el.title || el.key }
              }))
              .filter((el) => !actives.includes(el.title))
          ]
        } else {
          return prev
        }
      }, [])
      return [...this.inactiveViewFilters, ...topFilters]
    },
    hasBookmarks() {
      const bookmarkIdx = this.filterBlocks.findIndex(
        (el) => el.type === 'bookmarks'
      )
      return (
        bookmarkIdx !== -1 &&
        this.filterBlocks[bookmarkIdx].options.length > 0 &&
        this.filterBlocks[bookmarkIdx].options[0].count > 0
      )
    },
    hasPersonalContent() {
      const editorIdx = this.filterBlocks.findIndex(
        (el) => el.type === 'editors'
      )
      return (
        editorIdx !== -1 &&
        this.filterBlocks[editorIdx].options.length > 0 &&
        this.filterBlocks[editorIdx].options[0].count > 0
      )
    },
    viewFilters() {
      return [
        ...(this.$route.params.tab !== 'people'
          ? [
              {
                title: 'Past year',
                type: 'view',
                query: {
                  view: 'year'
                }
              },
              {
                title: 'Past 6 months',
                type: 'view',
                query: {
                  view: 'sixMonths'
                }
              },
              {
                title: 'Past month',
                type: 'view',
                query: {
                  view: 'month'
                }
              }
            ]
          : []),
        ...((this.$route.params.tab || 'all') === 'slides'
          ? [
              {
                title: 'Popular',
                type: 'view',
                query: {
                  view: 'popular'
                }
              }
            ]
          : []),
        ...(this.hasBookmarks ||
        (this.$route.query && this.$route.query.view === 'bookmarks')
          ? [
              {
                title: 'Bookmarked',
                type: 'view',
                query: {
                  view: 'bookmarks'
                }
              }
            ]
          : []),
        ...(this.hasPersonalContent ||
        (this.$route.query && this.$route.query.view === 'myContent')
          ? [
              {
                title: 'My content',
                type: 'view',
                query: {
                  view: 'myContent'
                }
              }
            ]
          : [])
      ]
    },
    inactiveViewFilters() {
      const actives = this.activeFilters.map((el) => el.title)
      return this.viewFilters.filter((el) => !actives.includes(el.title))
    }
  },
  watch: {
    filtersLoading(newVal) {
      if (!newVal) {
        this.$nextTick(this.checkPillsOverflow)
      } else {
        this.scrollToStart()
      }
    }
  },
  mounted() {
    this.checkPillsOverflow()
    window.addEventListener('resize', () => {
      this.checkPillsOverflow()
    })
  },
  methods: {
    ...mapActions(['setSearchEnrichmentContext']),
    handleFilterClicked(query) {
      this.enrichSearchContext(query)
      let oldQuery = Object.assign({}, this.$route.query)
      let newRoute = {}
      if (this.isActive(query)) {
        Object.keys(query).forEach((key) => {
          if (!oldQuery[key].includes(',')) delete oldQuery[key]
          else {
            let oldValue = oldQuery[key].split(',')
            oldValue.splice(
              oldValue.findIndex((el) => el === routeSafe(query[key])),
              1
            )
            oldQuery[key] = oldValue.join(',')
          }
        })
        let { query: queryFromRoute, ...rest } = this.$route
        this.$console.debug(`Removing ${queryFromRoute} from route`)
        newRoute = {
          ...rest,
          query: oldQuery
        }
      } else {
        const isViewToggle = !!query.view
        const viewActive = !isEmpty(this.$route.query.view || {})
        Object.keys(query).forEach((key) => {
          if (key in oldQuery && !(isViewToggle && viewActive)) {
            oldQuery[key] = [
              ...oldQuery[key].split(','),
              ...[routeSafe(query[key])]
            ].join(',')
          } else if (isViewToggle && viewActive) {
            delete oldQuery.view
            oldQuery[key] = routeSafe(query[key])
          } else {
            oldQuery[key] = routeSafe(query[key])
          }
        })
        newRoute = {
          ...this.$route,
          query: oldQuery
        }
      }
      this.$router.push(newRoute)
    },
    enrichSearchContext(query) {
      const viewMapping = {
        myContent: searchDetail.filter_suggestion.my_content,
        bookmarks: searchDetail.filter_suggestion.bookmarks,
        recent: searchDetail.filter_suggestion.latest
      }
      const facetMapping = {
        labels: searchDetail.filter_suggestion.label,
        topics: searchDetail.filter_suggestion.topic,
        content_classes: searchDetail.filter_suggestion.content_class,
        industries: searchDetail.filter_suggestion.industry,
        organisations: searchDetail.filter_suggestion.organisation
      }
      const searchEnrichmentDetail = query.view
        ? viewMapping[query.view]
        : facetMapping[Object.keys(query)[0]]
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.filter_suggestion,
        detail: searchEnrichmentDetail
      })
    },
    isActive(query) {
      return Object.keys(query).every((key) =>
        filterFromRoute(this.$route, key).includes(query[key])
      )
    },
    checkPillsOverflow() {
      this.scrollEnabled = true
      this.$nextTick(() => {
        const pills = Array.from(document.getElementsByClassName('pill'))
        if (pills.length === 0) return
        const pillsWidth = pills.reduce(
          (prev, curr) => prev + curr.getBoundingClientRect().width + 8,
          0
        )
        const containerWidth = document
          .getElementById('topfiltersuggestions')
          .getBoundingClientRect().width
        this.scrollEnabled = pillsWidth > containerWidth
        if (this.scrollEnabled) this.checkArrowsShown()
      })
    },
    checkArrowsShown() {
      const container = document.getElementById('pills-scroll')
      this.arrowVisible.left = container?.scrollLeft >= 3
      this.arrowVisible.right =
        container?.scrollLeft <
        container?.scrollWidth - container?.clientWidth - 3
    },
    handleScroll() {
      this.checkArrowsShown()
    },
    scroll(direction) {
      const container = document.getElementById('pills-scroll')
      container?.scrollBy({
        top: 0,
        left: direction * 500,
        behavior: 'smooth'
      })
      setTimeout(this.checkArrowsShown, 500)
    },
    scrollToStart() {
      const container = document.getElementById('pills-scroll')
      container?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      setTimeout(this.checkArrowsShown, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.pills {
  &-container {
    position: relative;
  }

  &-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-right: 3rem;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari, Opera */
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    &.scroll {
      flex-flow: column wrap;
      height: 2rem;
    }
  }

  &-arrow {
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
      transform: rotate(180deg);
    }

    &-img {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.2rem;
      background: white;
      border: 2px solid #dddfe2;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        background: #dddfe2;
      }
    }

    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.987259) 8.59%,
      rgba(255, 255, 255, 0.951407) 17.48%,
      rgba(255, 255, 255, 0.896) 26.53%,
      rgba(255, 255, 255, 0.824593) 35.61%,
      rgba(255, 255, 255, 0.740741) 44.59%,
      rgba(255, 255, 255, 0.648) 53.34%,
      rgba(255, 255, 255, 0.549926) 61.73%,
      rgba(255, 255, 255, 0.450074) 69.63%,
      rgba(255, 255, 255, 0.352) 76.9%,
      rgba(255, 255, 255, 0.259259) 83.41%,
      rgba(255, 255, 255, 0.175407) 89.03%,
      rgba(255, 255, 255, 0.104) 93.63%,
      rgba(255, 255, 255, 0.0485926) 97.08%,
      rgba(255, 255, 255, 0.0127407) 99.25%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>
