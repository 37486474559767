<template>
  <div id="slidesblock" class="discover">
    <p id="slidesblocktitle" class="header semi-bold xs mb-4 row">
      <img src="@/assets/icons/slides.svg" alt="" class="header-img mr-3" />
      {{ header }}
    </p>
    <div v-if="!allImagesLoaded" class="discover-loading row full-width">
      <b-skeleton v-for="el in 3" :key="el" height="100%" />
    </div>
    <div class="discover-slides">
      <SlideVisualisation
        v-for="(slide, idx) in slideData.subresources"
        :key="slide.uuid"
        :subresource="slide"
        :index="idx"
        :loaded-resources="imagesLoaded + imagesErrored"
        :total-resources="pageSize"
        :class="{ hidden: !allImagesLoaded }"
        :search-id="searchId"
        :rank="idx + 1"
        location="slidesblock"
        @load="imagesLoaded += 1"
        @cantLoad="imagesErrored += 1"
      />
    </div>
    <div class="discover-more link mt-4" @click="showMoreSlides">
      <p class="link blue">See more <span class="link bold">slides</span></p>
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="discover-more-img"
      />
    </div>
  </div>
</template>

<script>
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { mapActions, mapGetters } from 'vuex'
import SlideVisualisation from '../SlideVisualisation.vue'

export default {
  name: 'SlidesDiscover',
  components: { SlideVisualisation },
  props: {
    searchRequestProps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    slideData: {},
    pageSize: 3,
    imagesLoaded: 0,
    imagesErrored: 0,
    searchId: ''
  }),
  computed: {
    ...mapGetters(['searchEnrichmentContext']),
    allImagesLoaded() {
      return this.imagesLoaded + this.imagesErrored === this.pageSize
    },
    header() {
      return this.$route.query.query
        ? `Slides for “${this.$route.query.query}”`
        : 'Slides'
    }
  },
  async mounted() {
    try {
      const { response_data } = await this.getSearchResourcesPage({
        ...this.searchRequestProps,
        data: {
          ...this.searchRequestProps.data,
          ...this.searchEnrichmentContext,
          tab: 'all'
        },
        extraParams: {
          ...(this.searchRequestProps.extraParams || {}),
          page_size: this.pageSize
        },
        resourceType: 'slides'
      })
      this.$emit('completed')
      if (response_data.subresources?.length !== 3) {
        this.$emit('hide')
      } else {
        this.slideData = response_data
      }
      this.searchId =
        response_data?.search_id || response_data?.results?.search_id || ''
    } catch (e) {
      this.$emit('hide')
    }
  },
  methods: {
    ...mapActions(['getSearchResourcesPage', 'setSearchEnrichmentContext']),
    showMoreSlides() {
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.slideblock
      })
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          tab: 'slides'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.discover {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  border: 1px solid rgba(#000, 8%);
  border-radius: 8px;
  background: #f9f9fa;

  &-slides {
    width: 100%;
    max-width: 1500px;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  &-more {
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 0.5rem;

    &-img {
      filter: invert(22%) sepia(96%) saturate(2594%) hue-rotate(218deg)
        brightness(99%) contrast(99%);
      transform: rotate(180deg);
      height: 1rem;
    }
  }
  &.header {
    color: #303032;

    .header-img {
      height: 1.2rem;
    }
  }

  &-loading {
    gap: 0.5rem;
  }
}

.b-skeleton {
  margin-top: 0 !important;
  aspect-ratio: 16 / 9;
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
