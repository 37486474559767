var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "orb",
      style: {
        height: _vm.avatarHeight + "px",
        width: _vm.avatarWidth + "px",
        backgroundColor: _vm.color
      }
    },
    [
      _c(
        "p",
        {
          staticClass: "ghost-orb not-selectable",
          style: { "font-size": _vm.avatarHeight * 0.4 + "px" }
        },
        [_vm._v(" " + _vm._s(_vm.croppedInitials) + " ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }