








import Vue from 'vue'

export default Vue.extend({
  name: 'UserTopicList',

  props: {
    topics: {
      type: Array as () => string[],
      default: () => []
    }
  }
})
