var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "u-columns": true,
        "is-vcentered": true,
        "pa-2": true,
        "is-clickable": _vm.showModal,
        selectable: _vm.showModal
      },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "u-column u-columns center is-narrow is-vcentered" },
        [
          _c(
            "UserAvatar",
            {
              attrs: {
                avatar: _vm.avatar,
                width: _vm.size,
                height: _vm.size,
                orb: _vm.orb
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "orb",
                    fn: function() {
                      return [_vm._t("orb")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-column hor-avatar-text ml-4" },
        [
          _vm._t("title", function() {
            return [
              _c(
                "p",
                { staticClass: "body xs has-text-left mb-2 user-title" },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]
          }),
          _vm._t("subtitle", function() {
            return _vm._l(_vm.subtitles, function(sub, i) {
              return _c(
                "p",
                {
                  key: i,
                  staticClass: "body has-text-left body-text xxs user-subtitle"
                },
                [_vm._v(" " + _vm._s(sub) + " ")]
              )
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }