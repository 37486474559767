































import Vue from 'vue'
export default Vue.extend({
  name: 'ResultBlock',

  props: {
    blockTitle: {
      type: String,
      required: true
    },

    blockIcon: {
      type: String,
      default: undefined
    },

    resultCount: {
      type: Number,
      default: undefined
    },

    showHeader: {
      type: Boolean,
      default: false
    },

    hasBorder: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isPeopleBlock() {
      return this.blockTitle === 'People'
    }
  }
})
