<template>
  <div
    :style="{
      height: avatarHeight + 'px',
      width: avatarWidth + 'px',
      backgroundColor: color
    }"
    class="orb"
  >
    <p
      class="ghost-orb not-selectable"
      :style="{ 'font-size': avatarHeight * 0.4 + 'px' }"
    >
      {{ croppedInitials }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'InitialsOrb',

  props: {
    initials: {
      type: String,
      required: true
    },

    color: {
      type: String,
      default: ''
    },

    avatarWidth: {
      type: [Number, String],
      required: true
    },

    avatarHeight: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    croppedInitials() {
      return this.initials.slice(0, 2)
    }
  }
}
</script>

<style scoped lang="scss">
.orb {
  border-radius: 100%;

  display: flex;
}

.ghost-orb {
  margin: auto auto;
  width: fit-content;
  height: fit-content;
  color: $white;
  font-weight: bolder;
  pointer-events: none;
}

.not-selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
