<template>
  <div id="videoblock" class="discover">
    <p id="videoblocktitle" class="header semi-bold xs mb-4 row">
      <img src="@/assets/icons/video.svg" alt="" class="header-img mr-3" />
      {{ header }}
    </p>
    <div v-if="!allImagesLoaded" class="discover-loading row full-width">
      <b-skeleton v-for="el in 3" :key="el" height="100%" />
    </div>
    <div
      class="discover-videos"
      :class="{
        'discover-videos-grid': videoData && videoData.length === pageSize
      }"
    >
      <div
        v-for="(video, idx) in videoData"
        :key="video.uuid"
        class="discover-videos-item"
      >
        <ResourceCard
          :resource="video"
          :rank="idx + 1"
          location="videoblock"
          :search-id="searchId"
          :class="{ hidden: !allImagesLoaded }"
          @load="imagesLoaded += 1"
          @cantLoad="imagesErrored += 1"
          @gotoResource="$emit('gotoResource', video)"
        />
      </div>
    </div>
    <div class="discover-more link mt-4" @click="showMoreVideos">
      <p
        v-if="!videoData.length || videoData.length === pageSize"
        class="link blue"
      >
        See more <span class="link bold">videos</span>
      </p>
      <p v-else class="link blue">
        Go to the <span class="link bold">video tab</span>
      </p>
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="discover-more-img"
      />
    </div>
  </div>
</template>

<script>
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { ORDERING_MAP, SORT_TYPES_ENUM } from '@c/models/SortingFiltering'
import { SearchFacet } from 'umanai-vuex/src/store/modules/resources.js'
import { mapActions, mapGetters } from 'vuex'
import ResourceCard from '../ResourceCard.vue'

export default {
  name: 'VideosDiscover',
  components: { ResourceCard },
  props: {
    searchRequestProps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    videoData: [],
    pageSize: 3,
    imagesLoaded: 0,
    imagesErrored: 0,
    searchId: ''
  }),
  computed: {
    ...mapGetters(['searchEnrichmentContext']),
    allImagesLoaded() {
      return (
        this.imagesLoaded + this.imagesErrored ===
        (this.videoData?.length || this.pageSize)
      )
    },
    header() {
      return this.$route.query.query
        ? `Videos for “${this.$route.query.query}”`
        : 'Videos'
    }
  },
  async mounted() {
    try {
      const { response_data } = await this.getSearchResourcesPage({
        ...this.searchRequestProps,
        data: {
          ...this.searchRequestProps.data,
          ...this.searchEnrichmentContext,
          tab: 'all',
          types: ['video'],
          sort_by: ORDERING_MAP[SORT_TYPES_ENUM.CREATION_DATE]
        },
        extraParams: {
          ...this.searchRequestProps.extraParams,
          page_size: this.pageSize,
          included_facets: [
            SearchFacet.types,
            SearchFacet.labels,
            SearchFacet.authors,
            SearchFacet.modified_dates,
            SearchFacet.created_dates,
            SearchFacet.file_locations,
            SearchFacet.bookmarks
          ].join(' ')
        },
        resourceType: 'video'
      })
      this.$emit('completed')
      if ((response_data.resources?.length || 0) === 0) {
        this.$emit('hide')
      } else {
        this.videoData = response_data.resources || []
      }
      this.searchId =
        response_data?.search_id || response_data?.results?.search_id || ''
    } catch (e) {
      this.$emit('hide')
    }
  },
  methods: {
    ...mapActions(['getSearchResourcesPage', 'setSearchEnrichmentContext']),
    showMoreVideos() {
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.slideblock
      })
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          tab: 'videos'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.discover {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  border: 1px solid rgba(#000, 8%);
  border-radius: 8px;
  background: #f9f9fa;

  &-videos {
    width: 100%;
    max-width: 1500px;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    &.discover-videos-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &:not(.discover-videos-grid) {
      display: flex;
      flex-flow: row nowrap;
      & > .discover-videos-item {
        max-width: 450px;
      }
    }
  }

  &-more {
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 0.5rem;

    &-img {
      filter: invert(22%) sepia(96%) saturate(2594%) hue-rotate(218deg)
        brightness(99%) contrast(99%);
      transform: rotate(180deg);
      height: 1rem;
    }
  }
  &.header {
    color: #303032;

    .header-img {
      height: 1.2rem;
    }
  }

  &-loading {
    gap: 0.5rem;
  }
}

.b-skeleton {
  margin-top: 0 !important;
  aspect-ratio: 16 / 9;
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
