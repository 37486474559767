var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "background-white": true,
        "result-block": _vm.hasBorder
      }
    },
    [
      _vm.showHeader
        ? _c(
            "div",
            {
              class: {
                "pt-5": true,
                "pl-7": _vm.isPeopleBlock
              }
            },
            [
              _c("p", { staticClass: "body md bold" }, [
                _vm._v(" " + _vm._s(_vm.blockTitle) + " ")
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "ph-5": _vm.isPeopleBlock,
            "pv-3": _vm.isPeopleBlock
          }
        },
        [_c("div", [_vm._t("default")], 2)]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }