var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters", style: _vm.secondaryHeightStyle },
    [
      !_vm.searchErrored
        ? _c("PillFilterBlock", { staticClass: "mb-4" })
        : _vm._e(),
      !(_vm.searchEmpty || _vm.searchErrored)
        ? _c(
            "div",
            {
              staticClass: "filters-primary",
              attrs: { id: "filters-primary" }
            },
            [
              _c("div", { staticClass: "u-column is-narrow" }, [
                !_vm.resultAmountLoading && _vm.resultAmount >= 0
                  ? _c("span", [
                      _vm._v(
                        "Showing " + _vm._s(_vm.resultAmount) + " results "
                      ),
                      _vm.query
                        ? _c("span", [
                            _vm._v("for '" + _vm._s(_vm.query) + "'")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "filters-primary-showmore" },
                [
                  _c("AdvancedFiltersButton", {
                    attrs: {
                      id: "filterdropdown",
                      active: _vm.showSecondaryFilters
                    },
                    on: {
                      toggle: function($event) {
                        return _vm.showMoreFilters()
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      !(_vm.searchEmpty || _vm.searchErrored)
        ? _c("Transition", { attrs: { name: "popout", duration: 300 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showSecondaryFilters,
                    expression: "showSecondaryFilters"
                  }
                ],
                class: {
                  "filters-secondary": true,
                  "filters-secondary-shown": _vm.secondaryFiltersShown
                }
              },
              [
                _c(
                  "HorizontalFilters",
                  _vm._g(
                    {
                      staticClass: "pb-4 horizontal-filters",
                      attrs: {
                        "top-filters": _vm.advancedFilters,
                        "filters-loading": _vm.filtersLoading
                      }
                    },
                    _vm.$listeners
                  )
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }