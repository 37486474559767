var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "topic-list-container" },
    _vm._l(_vm.topics, function(topic, idx) {
      return _c("div", { key: idx, staticClass: "user-topic" }, [
        _vm._v(" " + _vm._s(topic) + " ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }