var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "200px" } },
    [
      _c("div", { staticClass: "mv-2" }, [_vm._t("before-resources")], 2),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.handleInfiniteScroll,
              expression: "handleInfiniteScroll"
            }
          ],
          class: _vm.gridClass,
          attrs: {
            "infinite-scroll-disabled": _vm.loadingOrDone,
            "infinite-scroll-distance": "100",
            "infinite-scroll-throttle-delay": "500"
          }
        },
        [
          _vm.visualisationType === _vm.visualisationTypes.SNIPPET ||
          _vm.visualisationType === _vm.visualisationTypes.USER_CARD
            ? _c(
                "div",
                { ref: "masonry", staticStyle: { "min-width": "100%" } },
                [
                  _c(
                    "vue-flex-waterfall",
                    {
                      attrs: {
                        col: _vm.masonryCols,
                        "col-spacing": "14",
                        "break-at": _vm.masonryBreaks
                      }
                    },
                    _vm._l(_vm.resources, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "mb-3" },
                        [
                          _vm.visualisationType ===
                          _vm.visualisationTypes.SNIPPET
                            ? _c("SnippetVisualisation", {
                                attrs: { snippet: item }
                              })
                            : _vm.visualisationType ===
                              _vm.visualisationTypes.USER_CARD
                            ? _c("UserVisualisation", {
                                staticClass: "data-visualisation-scroll",
                                staticStyle: { "min-width": "20vw" },
                                attrs: { user: item, rank: index + 1 }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._l(_vm.resources, function(item, index) {
                return _c(
                  "div",
                  {
                    key: item.uuid,
                    class:
                      _vm.customClasses ||
                      Object.assign(
                        {},
                        _vm.containerClasses[_vm.visualisationType]
                      )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "u-columns",
                        staticStyle: { "flex-direction": "column" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "u-column is-narrow" },
                          [
                            _vm.visualisationType ===
                            _vm.visualisationTypes.LIST
                              ? _c(
                                  "ResourceVisualisation",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "data-visualisation-scroll",
                                        attrs: {
                                          "custom-props": _vm.customProps,
                                          "visualisation-type":
                                            _vm.visualisationType,
                                          "uses-placeholders":
                                            _vm.usesPlaceholders,
                                          "show-topics": _vm.showTopics,
                                          rank: index + 1
                                        },
                                        on: {
                                          getItems: function(x) {
                                            return _vm.$emit("getItems", {
                                              query: x,
                                              resource: item
                                            })
                                          },
                                          downloadResource: function($event) {
                                            return _vm.downloadResource(item)
                                          },
                                          gotoResource: function($event) {
                                            return _vm.gotoResource(item)
                                          }
                                        }
                                      },
                                      "ResourceVisualisation",
                                      _vm.createProps(item),
                                      false
                                    ),
                                    _vm.$listeners
                                  )
                                )
                              : _vm._e(),
                            _vm.visualisationType ===
                            _vm.visualisationTypes.SLIDE
                              ? _c("SlideVisualisation", {
                                  staticClass: "data-visualisation-scroll",
                                  attrs: {
                                    subresource: item,
                                    index: index,
                                    "loaded-resources": _vm.resources.length,
                                    "total-resources": _vm.totalResourceAmount,
                                    rank: index + 1
                                  },
                                  on: {
                                    load: function($event) {
                                      _vm.imagesLoaded += 1
                                    },
                                    cantLoad: function($event) {
                                      _vm.imagesLoaded += 1
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.visualisationType ===
                            _vm.visualisationTypes.VIDEO
                              ? _c("ResourceCard", {
                                  staticClass: "data-visualisation-scroll",
                                  attrs: {
                                    resource: item,
                                    highlight: item.highlight,
                                    rank: index + 1
                                  },
                                  on: {
                                    load: function($event) {
                                      _vm.imagesLoaded += 1
                                    },
                                    cantLoad: function($event) {
                                      _vm.imagesLoaded += 1
                                    },
                                    gotoResource: function($event) {
                                      return _vm.gotoResource(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            index === 0 &&
                            _vm.isAllResults &&
                            Object.keys(_vm.searchRequestProps).length !== 0 &&
                            !_vm.hideSlidesDiscover
                              ? _c("SlidesDiscover", {
                                  attrs: {
                                    "search-request-props":
                                      _vm.searchRequestProps
                                  },
                                  on: {
                                    hide: function($event) {
                                      _vm.hideSlidesDiscover = true
                                    },
                                    completed: function($event) {
                                      _vm.discoverBlocksCompleted += 1
                                    }
                                  }
                                })
                              : _vm._e(),
                            (index === 5 ||
                              (_vm.allLoaded &&
                                index < 5 &&
                                index === _vm.resources.length - 1)) &&
                            _vm.isAllResults &&
                            Object.keys(_vm.searchRequestProps).length !== 0 &&
                            !_vm.hideSheetsDiscover &&
                            _vm.discoverBlocksTriggered
                              ? _c("SheetsDiscover", {
                                  attrs: {
                                    "search-request-props":
                                      _vm.searchRequestProps
                                  },
                                  on: {
                                    hide: function($event) {
                                      _vm.hideSheetsDiscover = true
                                    },
                                    completed: function($event) {
                                      _vm.discoverBlocksCompleted += 1
                                    }
                                  }
                                })
                              : _vm._e(),
                            (index === 9 ||
                              (_vm.allLoaded &&
                                index < 9 &&
                                index === _vm.resources.length - 1)) &&
                            _vm.isAllResults &&
                            Object.keys(_vm.searchRequestProps).length !== 0 &&
                            !_vm.hideVideosDiscover &&
                            _vm.discoverBlocksTriggered
                              ? _c("VideosDiscover", {
                                  attrs: {
                                    "search-request-props":
                                      _vm.searchRequestProps
                                  },
                                  on: {
                                    hide: function($event) {
                                      _vm.hideVideosDiscover = true
                                    },
                                    completed: function($event) {
                                      _vm.discoverBlocksCompleted += 1
                                    },
                                    gotoResource: _vm.gotoResource
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              })
        ],
        2
      ),
      _vm.loading
        ? _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("ResourceGridLoading", {
                attrs: {
                  amount: 1,
                  "visualisation-type": _vm.visualisationType
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ResourceModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }