var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.clickable ? "router-link" : "div",
    { tag: "component", attrs: { to: _vm.profileRoute } },
    [
      _c(
        "div",
        {
          staticClass: "user",
          on: {
            click: function($event) {
              return _vm.goToProfile()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "user-header" },
            [
              _vm.userAvatar && !_vm.avatarError
                ? _c("img", {
                    staticClass: "user-header-img",
                    attrs: { src: _vm.userAvatar, alt: "" },
                    on: {
                      error: function($event) {
                        _vm.avatarError = true
                      }
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass: "user-header-img",
                      style: "background: " + _vm.userColor + ";"
                    },
                    [_vm._v(" " + _vm._s(_vm.userInitials) + " ")]
                  ),
              _c("div", { staticClass: "user-header-info" }, [
                _c("div", { staticClass: "user-header-info-name" }, [
                  _vm._v(" " + _vm._s(_vm.userFullName) + " ")
                ]),
                _vm.userRole
                  ? _c("div", { staticClass: "user-header-info-title" }, [
                      _vm._v(" " + _vm._s(_vm.userRole) + " ")
                    ])
                  : _vm._e()
              ]),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: "Show CV",
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _vm.user.cv_ids && _vm.user.cv_ids.length
                    ? _c("Button", {
                        staticClass: "user-header-cv",
                        attrs: { icon: "document", type: "white" },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openUserCV.apply(null, arguments)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showBody
            ? _c("div", { staticClass: "user-body" }, [
                _vm.visibleTopics && _vm.visibleTopics.length && _vm.showTopics
                  ? _c("div", { staticClass: "user-body-block" }, [
                      _c("p", { staticClass: "user-body-block-title" }, [
                        _vm._v("Core expertise")
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-body-block-pills" },
                        _vm._l(_vm.visibleTopics, function(topic) {
                          return _c("Tag", {
                            key: topic.uuid,
                            attrs: {
                              text: topic.name,
                              type: "white",
                              size: "xs"
                            }
                          })
                        }),
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.visibleOrganisations && _vm.showOrganisations
                  ? _c("div", { staticClass: "user-body-block" }, [
                      _c("p", { staticClass: "user-body-block-title" }, [
                        _vm._v("Works on")
                      ]),
                      _c("p", { staticClass: "user-body-block-list" }, [
                        _vm._v(" " + _vm._s(_vm.visibleOrganisations) + " ")
                      ])
                    ])
                  : _vm._e(),
                _vm.visibleIndustries && _vm.showIndustries
                  ? _c("div", { staticClass: "user-body-block" }, [
                      _c("p", { staticClass: "user-body-block-title" }, [
                        _vm._v("Industries")
                      ]),
                      _c("p", { staticClass: "user-body-block-list" }, [
                        _vm._v(" " + _vm._s(_vm.visibleIndustries) + " ")
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }