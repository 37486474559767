var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.missingWords.length > 0
    ? _c("div", { staticClass: "missing-words-container" }, [
        _vm.missingWords.length === 1
          ? _c("div", [
              _vm._v(" Missing word: "),
              _c("span", { staticClass: "missing-words" }, [
                _vm._v(_vm._s(_vm.missingWords[0]))
              ]),
              _vm._v(" | Must include: "),
              _c(
                "span",
                {
                  staticClass: "must-include",
                  on: { click: _vm.mustIncludeClick }
                },
                [_vm._v(_vm._s(_vm.missingWords[0]))]
              )
            ])
          : _c("div", [
              _vm._v(" Missing words: "),
              _c("span", { staticClass: "missing-words" }, [
                _vm._v(_vm._s(_vm.joinedMissingWords))
              ])
            ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }