



































import localFilePlaceholder from '@c/assets/placeholders/localfile-placeholder.svg'
import Placeholder1 from '@c/assets/placeholders/no-image-placeholder.svg'
import DecoratedTextMixin from '@c/mixins/DecoratedTextMixin'
import {
  Activity,
  Highlight,
  IntegrationData,
  SubResource,
  VISUALISATION_TYPES_ENUM
} from '@c/models/Resource'
import User from '@c/models/User'
import Workspace from '@c/models/Workspace'
import IntegrationPlaceholder from '@c/shared/atoms/icons/IntegrationPlaceholder.vue'
import ResourceListItem from '@c/shared/molecules/object-visualisations/resource/ResourceListItem.vue'
import { HighlightedText } from '@c/shared/molecules/structural/blocks/interfaces'
import { sample } from 'lodash-es'
import Vue, { PropType } from 'vue'

const PLACEHOLDERS: string[] = [Placeholder1]

export default Vue.extend({
  name: 'ResourceVisualisation',

  components: {
    ResourceListItem,
    IntegrationPlaceholder
  },

  mixins: [DecoratedTextMixin],

  props: {
    visualisationType: {
      type: String as () => VISUALISATION_TYPES_ENUM,
      default: VISUALISATION_TYPES_ENUM.CARD
    },

    canAdd: {
      type: Boolean,
      default: true
    },

    canDelete: {
      type: Boolean,
      default: false
    },

    uuid: {
      type: String,
      default: undefined
    },

    link: {
      type: String,
      required: true
    },

    highlights: {
      type: Array as () => Highlight[] | undefined,
      default: undefined
    },

    resourceIntegration: {
      type: Object as () => IntegrationData,
      default: null
    },

    image: {
      type: [String, Object] as PropType<string | object[]>,
      default: undefined
    },

    title: {
      type: [String, Array] as PropType<string | HighlightedText[]>,
      required: true
    },

    description: {
      type: [String, Array] as PropType<string | HighlightedText[]>,
      default: ''
    },

    blobMimetype: {
      type: String,
      default: ''
    },

    viewsActive: {
      type: Boolean,
      default: false
    },

    workspaces: {
      type: Array as () => Workspace[],
      default: function (): Workspace[] {
        return []
      }
    },

    currentUser: {
      type: Object as () => User,
      required: true
    },

    usesPlaceholders: {
      type: Boolean,
      default: true
    },

    showTopics: {
      type: Boolean,
      default: false
    },

    subresources: {
      type: Array as () => SubResource[],
      default: () => []
    },

    contentClass: {
      type: String as () => string,
      default: undefined
    },

    resourceType: {
      type: String as () => string,
      default: undefined
    },

    organisations: {
      type: Array as () => string[],
      default: undefined
    },

    industries: {
      type: Array as () => string[],
      default: undefined
    },

    topics: {
      type: Object as () => object,
      default: undefined
    },

    customProps: {
      type: Object as () => object,
      default: undefined
    },

    activity: {
      type: Array as () => Activity[],
      default: () => []
    },

    similarPages: {
      type: Array,
      default: () => []
    },
    label: {
      type: Object,
      default: () => {}
    },
    missingWords: {
      type: Array,
      default: () => []
    },
    bookmark: {
      type: [String, null],
      default: null
    },
    highlight: {
      type: [Object, undefined],
      default: undefined
    },
    traceId: {
      type: String,
      required: true
    },
    rank: {
      type: Number,
      required: true
    }
  },

  computed: {
    placeholder(): string {
      if (this.blobMimetype) {
        return localFilePlaceholder as string
      }
      return sample(PLACEHOLDERS)!! as string
    },

    imageOrRandomPlaceholder(): string | object[] {
      if (
        this.visualisationType === VISUALISATION_TYPES_ENUM.TILE ||
        !this.usesPlaceholders
      ) {
        return this.image
      }
      return this.image || this.placeholder
    },

    visualisationTypes() {
      return VISUALISATION_TYPES_ENUM
    },

    decoratedTitle(): HighlightedText[] | string {
      // Show decorated title (with correct boldness)
      // Otherwise fall back to the undecorated title.
      if (this.highlights) {
        const highlightedContent = this.highlights.find(
          (x) => x.field === 'title'
        )
        if (highlightedContent?.highlight) {
          return this.decorateText(highlightedContent.highlight)
        }
      }
      return this.title
    },

    decoratedContent(): HighlightedText[] | string {
      // Show decorated content (with correct boldness)
      // Otherwise fall back to the description.
      if (this.highlights) {
        const highlightedContent = this.highlights.find(
          (x) => x.field === 'content'
        )
        if (highlightedContent?.highlight) {
          return this.decorateText(highlightedContent.highlight)
        }
      }
      return this.description
    }
  }
})
