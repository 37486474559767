import moment from 'moment'

export enum DateFilters {
  ANYTIME = 'ANYTIME',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS'
}

export const DateFilterToQP: Record<DateFilters, object> = {
  [DateFilters.ANYTIME]: {},
  [DateFilters.LAST_30_DAYS]: {
    created_after: moment().subtract(30, 'days').toISOString()
  },
  [DateFilters.LAST_90_DAYS]: {
    created_after: moment().subtract(90, 'days').toISOString()
  },
  [DateFilters.LAST_12_MONTHS]: {
    created_after: moment().subtract(12, 'months').toISOString()
  }
}