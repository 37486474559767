var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty" }, [
    _c("img", { staticClass: "empty-icon", attrs: { src: _vm.icon, alt: "" } }),
    _c("p", {
      staticClass: "empty-title",
      domProps: { innerHTML: _vm._s(_vm.title) }
    }),
    _c("p", { staticClass: "empty-subtitle" }, [
      _vm._v(" " + _vm._s(_vm.subtitle) + " ")
    ]),
    Object.keys(_vm.types).length > 0 || _vm.slideCount > 0
      ? _c(
          "div",
          { staticClass: "empty-tabs" },
          [
            _c("p", { staticClass: "empty-tabs-header" }, [
              _vm._v("Or check out these results in another tab")
            ]),
            _vm._l(_vm.types, function(count, type) {
              return _c("p", { key: type, staticClass: "empty-tabs-tab" }, [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(
                    _vm._s(count) +
                      " " +
                      _vm._s(type) +
                      _vm._s(count === 1 ? "" : "s")
                  )
                ]),
                _vm._v(" in the "),
                _c(
                  "span",
                  {
                    staticClass: "empty-tabs-tab-link",
                    on: {
                      click: function($event) {
                        return _vm.gotoTab(type)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.typeToTab[type]) + " tab →")]
                )
              ])
            }),
            _vm.slideCount > 0
              ? _c("p", { staticClass: "empty-tabs-tab" }, [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(
                      _vm._s(_vm.slideCount) +
                        " slide" +
                        _vm._s(_vm.slideCount === 1 ? "" : "s")
                    )
                  ]),
                  _vm._v(" in the "),
                  _c(
                    "span",
                    {
                      staticClass: "empty-tabs-tab-link",
                      on: {
                        click: function($event) {
                          return _vm.gotoTab("slides")
                        }
                      }
                    },
                    [_vm._v("Slides tab →")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }