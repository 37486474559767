var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resourcegrid-loading-container" }, [
    _vm.visualisationType !== _vm.visualisationTypes.SLIDE
      ? _c(
          "div",
          _vm._l(_vm.amount, function(index) {
            return _c("ResourceListItemLoading", {
              key: index,
              staticStyle: { "min-height": "200px" }
            })
          }),
          1
        )
      : _vm._e(),
    _vm.visualisationType === _vm.visualisationTypes.SLIDE
      ? _c(
          "div",
          _vm._l(_vm.amount, function(index) {
            return _c("SubresourceItemLoading", {
              key: index,
              staticStyle: { "min-height": "200px" }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }