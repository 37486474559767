



















import Vue from 'vue'

export default Vue.extend({
  name: 'BasicEmptyStateBlock',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: undefined
    }
  }
})
