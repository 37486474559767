import { render, staticRenderFns } from "./PillFilterBlock.vue?vue&type=template&id=30d2e438&scoped=true&"
import script from "./PillFilterBlock.vue?vue&type=script&lang=js&"
export * from "./PillFilterBlock.vue?vue&type=script&lang=js&"
import style0 from "./PillFilterBlock.vue?vue&type=style&index=0&id=30d2e438&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d2e438",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30d2e438')) {
      api.createRecord('30d2e438', component.options)
    } else {
      api.reload('30d2e438', component.options)
    }
    module.hot.accept("./PillFilterBlock.vue?vue&type=template&id=30d2e438&scoped=true&", function () {
      api.rerender('30d2e438', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/features/search-results/molecules/PillFilterBlock.vue"
export default component.exports