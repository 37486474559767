<template>
  <div
    ref="snippetVisualisation"
    class="snippet-visualisation preview-border-normal"
    :class="{ 'zoom-cursor': isHovering }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <transition name="fade">
      <b-button
        v-show="isHovering"
        type="is-primary"
        size="is-small"
        rounded
        icon-left="content-copy"
        class="btn-copy"
        @click="copyContent"
        >Copy</b-button
      >
    </transition>
    <div class="snippet-hover-scrim" @click.self="openDetail"></div>
    <InfoHover
      :is-hovering="isHovering"
      :references="snippet.references"
      :total-references="snippet.references.length"
    />
    <!-- eslint-disable-next-line -->
    <h1 v-html="highlightedTitle"></h1>
    <!-- eslint-disable-next-line -->
    <p v-html="highlightedContent"></p>
    <b-modal v-model="showDetail" trap-focus @close="hideDetail">
      <div
        class="modal-container pa-5"
        :class="{ 'scrollbar-visible': scrolling }"
        @scroll="handleScrolling"
      >
        <!-- here comes the text -->
        <div class="snippet-content">
          <h1>{{ snippet.title }}</h1>
          <p>{{ snippet.content }}</p>
        </div>
        <div class="modal-subheader">
          Appears in {{ snippet.references.length }}
          {{ snippet.references.length === 1 ? 'document' : 'documents' }}
        </div>
        <div v-for="(reference, index) in snippet.references" :key="index">
          <div class="reference mt-3">
            <DocumentTag
              class="u-column is-narrow mr-3"
              :mimetype="reference.resource.integrationfile.mimetype"
            />
            <a :href="getUrl(reference)" target="_blank" class="link">{{
              reference.resource.title
            }}</a>
          </div>
          <div class="integration-container">
            <a
              target="_blank"
              :href="reference.resource.integrationfile.folder_url"
            >
              <IntegrationLink
                :hover="true"
                :integration="reference.resource.integrationfile.integration"
                :integration-path="reference.resource.integrationfile.path"
                class="mr-2"
              />
            </a>
            <p>
              {{
                reference.resource.integrationfile.external_modified_by
                  ? ' Last modified by'
                  : ' Created by'
              }}

              {{ showFullName(reference.resource) }}

              on {{ showDate(reference.resource) }}
            </p>
            <b-tooltip
              :label="
                reference.resource.is_public ? 'Public' : 'Synced permissions'
              "
              type="is-white"
              position="is-right"
              class="ml-2"
            >
              <b-icon
                class="body body-text is-narrow"
                size="is-small"
                :icon="
                  reference.resource.is_public ? 'earth' : 'account-multiple'
                "
              />
            </b-tooltip>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IntegrationLink from '@c/shared/molecules/object-visualisations/resource/subcomponents/IntegrationLink'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { formatDateWTime } from '../../../../../../util'
import InfoHover from './subcomponents/InfoHover.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'SnippetVisualisation',
  components: {
    InfoHover,
    IntegrationLink,
    DocumentTag
  },
  props: {
    snippet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isHovering: false,
      mouseY: 0,
      showInfo: false,
      width: 0,
      showDetail: false,
      scrolling: false,
      highlightedContent: '',
      highlightedTitle: ''
    }
  },
  computed: {
    ...mapGetters(['windowWidth']),

    cardPositionClass() {
      if (this.mouseY < 0.33) return 'card-down'
      return 'card-up'
    }
  },
  watch: {
    windowWidth: {
      immediate: true,
      handler() {
        this.width = this.clamp(300, 500, this.windowWidth * 0.25)
      }
    }
  },
  mounted() {
    this.highlightTitle()
    this.highlightContent()
  },
  methods: {
    highlightString(value) {
      if (!this.$route.query.query) return value

      let result = ''
      const source = value
      for (let i = 0; i < source.length; i++) {
        if (
          source
            .substring(i, i + this.$route.query.query.length)
            .toLowerCase() == this.$route.query.query.toLowerCase()
        ) {
          result += `<span style="background:#FEEE9A;">${source.substr(
            i,
            this.$route.query.query.length
          )}</span>`
          i += this.$route.query.query.length - 1
        } else {
          result += source.substr(i, 1)
        }
      }
      return result
    },
    highlightTitle() {
      this.highlightedTitle = this.highlightString(this.snippet.title)
    },
    highlightContent() {
      this.highlightedContent = this.highlightString(this.snippet.content)
    },
    handleScrolling() {
      this.scrolling = true
      this.scrollingTimer = setTimeout(() => {
        this.scrolling = false
      }, 1000)
    },
    clamp(min, max, value) {
      if (value < min) return min
      if (value > max) return max
      return value
    },
    copyContent() {
      navigator.clipboard.writeText(
        this.snippet.title + '\n\n' + this.snippet.content
      )
    },
    getUrl(reference) {
      if (!reference.subresource.url) return reference.resource.url
      return reference.subresource.url
    },
    async onInfoMouseEnter(e) {
      this.mouseY = e.clientY / window.innerHeight
      this.showInfo = true
    },
    onInfoMouseLeave() {
      this.showInfo = false
    },
    onMouseEnter() {
      this.isHovering = true
    },
    onMouseLeave() {
      this.isHovering = false
    },
    hideDetail() {
      this.showDetail = false
    },
    openDetail() {
      this.isHovering = false
      this.showDetail = true
    },
    showFullName(resource) {
      try {
        return this.$umodel.full_name(
          resource.integrationfile.external_modified_by ||
            resource.integrationfile.external_created_by
        )
      } catch (error) {
        return ''
      }
    },
    showDate(resource) {
      return formatDateWTime(
        resource.integrationfile.external_modified_date ||
          resource.integrationfile.external_created_date ||
          this.lastModifiedAt ||
          this.createdAt
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.snippet-visualisation {
  padding: 1rem;
  position: relative;

  margin-bottom: 2rem;
  h1 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 1rem;
  }
  &.preview-border-normal {
    border: 0.5px solid rgba(0, 0, 0, 0.08);
  }
  &.zoom-cursor {
    cursor: zoom-in;
  }
  .snippet-hover-scrim {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.0127407) 6.47%,
      rgba(0, 0, 0, 0.0485926) 12.46%,
      rgba(0, 0, 0, 0.104) 18.04%,
      rgba(0, 0, 0, 0.175407) 23.29%,
      rgba(0, 0, 0, 0.259259) 28.27%,
      rgba(0, 0, 0, 0.352) 33.08%,
      rgba(0, 0, 0, 0.450074) 37.77%,
      rgba(0, 0, 0, 0.549926) 42.42%,
      rgba(0, 0, 0, 0.648) 47.11%,
      rgba(0, 0, 0, 0.740741) 51.91%,
      rgba(0, 0, 0, 0.824593) 56.9%,
      rgba(0, 0, 0, 0.896) 62.15%,
      rgba(0, 0, 0, 0.951407) 67.73%,
      rgba(0, 0, 0, 0.987259) 73.72%,
      #000000 80.19%
    );
    transform: rotate(180deg);
    opacity: 0;
    transition: opacity 0.4s ease;
    height: 100%;
    width: 100%;
  }
  &:hover {
    .snippet-hover-scrim {
      opacity: 0.15;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .integration-container {
    display: flex;
  }
  .reference {
    display: flex;
  }
  .card-wrapper {
    position: relative;
  }

  .link {
    color: $blue;
    font-weight: bolder;
    word-break: break-word;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }

  .modal-container {
    background: white;
    border-radius: 8px;
    overflow-y: auto;

    max-height: 90vh;
    scrollbar-width: thin;
    scrollbar-color: rgba(#000, 0) transparent;
    transition: all 0.2s ease;

    .modal-subheader {
      font-size: 1.2em;
      font-weight: 700;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .btn-copy {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }
  .scrollbar-visible::-webkit-scrollbar-thumb {
    background-color: rgba(#000, 0.4) !important;
  }
  .modal-container::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    transition: all 0.2s ease;
  }

  .modal-container::-webkit-scrollbar-thumb {
    transition: all 0.2s ease;
    background-color: rgba(#000, 0);
    border-radius: 100px;
  }
  .scrollbar-visible {
    scrollbar-color: rgba(#000, 0.4) transparent !important;
  }
  .integration-container {
    display: flex;
  }
  .snippet-content {
    padding: 1rem;
    background: #f9f9fa;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 4px 6px rgb(0 0 0 / 8%);
  }
}
</style>
