var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "has-background-white full",
      attrs: { id: "resource-visualisation-" + _vm.uuid }
    },
    [
      [_vm.visualisationTypes.LIST, _vm.visualisationTypes.LIST_DENSE].includes(
        _vm.visualisationType
      )
        ? _c(
            "ResourceListItem",
            _vm._g(
              _vm._b(
                {
                  staticClass: "full",
                  attrs: {
                    "show-topics": _vm.showTopics,
                    "is-dense":
                      _vm.visualisationType ===
                      _vm.visualisationTypes.LIST_DENSE
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "image",
                        fn: function() {
                          return [
                            !_vm.resourceIntegration
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.imageOrRandomPlaceholder,
                                      expression: "imageOrRandomPlaceholder"
                                    }
                                  ],
                                  staticClass: "resource-image"
                                })
                              : _c("IntegrationPlaceholder", {
                                  attrs: {
                                    "integration-data": _vm.resourceIntegration
                                  }
                                })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3579333817
                  )
                },
                "ResourceListItem",
                Object.assign({}, _vm.$props, _vm.customProps, {
                  image: _vm.image,
                  title: _vm.decoratedTitle,
                  description: _vm.decoratedContent
                }),
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }