











import BasicEmptyStateBlock from '@c/shared/molecules/empty-states/BasicEmptyStateBlock'

export default {
  name: 'SearchErrorState',
  components: { BasicEmptyStateBlock }
}
