var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "u-columns center",
          class: {
            "u-columns": true,
            center: true,
            "is-clickable": _vm.showModal,
            selectable: _vm.showModal
          },
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _c(
            "UserAvatar",
            {
              staticClass: "mb-4",
              attrs: {
                avatar: _vm.avatar,
                width: _vm.size,
                height: _vm.size,
                orb: _vm.orb
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "orb",
                    fn: function() {
                      return [_vm._t("orb")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      ),
      _vm._t("title", function() {
        return [
          _c(
            "p",
            { staticClass: "body xs has-text-centered mb-2 user-title" },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      }),
      _vm._t("subtitle", function() {
        return _vm._l(_vm.subtitles, function(sub, i) {
          return _c(
            "p",
            {
              key: i,
              staticClass: "body has-text-centered body-text xxs user-subtitle"
            },
            [_vm._v(" " + _vm._s(sub) + " ")]
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }