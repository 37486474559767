import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { Mutations } from '@/store'
import { filterFromRoute, routeSafe } from '@/util'
import {
  DateFilterToQP,
  DateFilters
} from '@c/features/search-results/organisms/secondary-blocks/interfaces'
import {
  ORDERING_MAP,
  SORT_TYPES_SLIDES_ENUM,
  SORT_TYPE_DEFAULT,
  SORT_TYPE_OPTIONS
} from '@c/models/SortingFiltering'
import Topic from '@c/models/Topic'
import ISO6391 from 'iso-639-1'
import { debounce, sortBy, uniq } from 'lodash-es'
import moment from 'moment'
import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import { mapActions, mapGetters } from 'vuex'
import { FilterBlockDefinition } from '../../features/search-results/interface'
import { getMimetypeFromTitle, getTitleFromMimetype } from './util'

// @ts-ignore
const { SearchFacet } = require('umanai-vuex/src/store/modules/resources.js')

export const facetToTitle: { [key: string]: string } = {
  [SearchFacet.industries]: 'Industry',
  [SearchFacet.organisations]: 'Organisation',
  [SearchFacet.types]: 'Document type',
  [SearchFacet.topics]: 'Topics',
  [SearchFacet.content_classes]: 'Content class',
  [SearchFacet.created_dates]: 'Created',
  [SearchFacet.modified_dates]: 'Last modified',
  [SearchFacet.languages]: 'Languages',
  [SearchFacet.mimetypes]: 'Mimetype',
  [SearchFacet.labels]: 'Quality labels',
  [SearchFacet.resolutions]: 'Resolutions',
  [SearchFacet.authors]: 'Author',
  [SearchFacet.bookmarks]: 'Bookmarks',
  [SearchFacet.editors]: 'Editors',
  [SearchFacet.file_locations]: 'File location'
}

export const defaultFacetOrdering: string[] = [
  SearchFacet.labels,
  SearchFacet.topics,
  SearchFacet.content_classes,
  SearchFacet.industries,
  SearchFacet.organisations,
  SearchFacet.authors,
  SearchFacet.mimetypes,
  SearchFacet.resolutions,
  SearchFacet.languages,
  SearchFacet.modified_dates,
  SearchFacet.created_dates,
  SearchFacet.types,
  SearchFacet.file_locations
]

export const BaseSecondarySearchMixin = Vue.extend({
  data(this: any) {
    let defaultFacets = {
      experts: undefined as any[] | undefined,
      extractedEntities: undefined as any | undefined,
      aggregations: {} as any,
      entities: undefined as undefined | { [key: string]: any },
      topics: [] as Topic[],
      organisations: [],
      labels: [],
      industries: [],
      mimetypes: undefined as any[] | undefined,
      authors: [],
      resourceTypes: undefined,
      insights: [] as any[],
      file_locations: []
    }
    return {
      filterBlocks: [] as any[],
      facets: defaultFacets as any,
      defaultFacets: defaultFacets as any,
      aggregations: {} as any,
      secondaryFilters: {
        currentSortType: this.getCurrentSortType(),
        dateFilter: DateFilters.ANYTIME
      } as any,
      loadingSecondaryData: false,
      totalResources: undefined as number | undefined,
      recreateFiltersOnPageLoad: false,
      filtersUsed: 0
    }
  },
  computed: {
    ...mapGetters([
      'resourceTypeFilter',
      'dateFilters',
      'currentWorkspaceMember',
      'activeWorkspace'
    ]),
    activeView(): any {
      return this.$route.query.view || 'allContent'
    },
    amountResources(): number {
      return (this as any).paginatorDataCount || 0
    },

    hasEntities(this: any) {
      return Boolean(this.facets.entities)
    },

    resourceFacets(): any[] {
      let facets = [
        SearchFacet.types,
        SearchFacet.content_classes,
        SearchFacet.topics,
        SearchFacet.organisations,
        SearchFacet.industries,
        SearchFacet.created_dates,
        SearchFacet.modified_dates,
        SearchFacet.languages,
        SearchFacet.authors,
        SearchFacet.mimetypes,
        SearchFacet.labels,
        SearchFacet.bookmarks,
        SearchFacet.editors,
        SearchFacet.file_locations
      ]
      if ((this as any).resourceTypeFilter === 'all') {
        facets.push(SearchFacet.insights)
      }
      return facets
    },

    subresourceFacets(): any[] {
      return [
        SearchFacet.topics,
        SearchFacet.organisations,
        SearchFacet.industries,
        SearchFacet.languages,
        SearchFacet.created_dates,
        SearchFacet.modified_dates,
        SearchFacet.labels,
        SearchFacet.bookmarks,
        ...(process.env.NODE_ENV != 'production'
          ? [SearchFacet.resolutions]
          : [])
      ]
    },
    videoFacets(): any[] {
      return [
        SearchFacet.types,
        SearchFacet.labels,
        SearchFacet.authors,
        SearchFacet.topics,
        SearchFacet.organisations,
        SearchFacet.industries,
        SearchFacet.modified_dates,
        SearchFacet.created_dates,
        SearchFacet.file_locations,
        SearchFacet.bookmarks
      ]
    },
    peopleFacets(): any[] {
      return [
        SearchFacet.topics,
        SearchFacet.organisations,
        SearchFacet.industries
      ]
    },
    snippetsFacets(): any[] {
      return [
        SearchFacet.created_dates,
        SearchFacet.modified_dates,
        SearchFacet.languages,
        SearchFacet.editors
      ]
    }
  },

  watch: {
    async dateFilter(this: any, newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$console.debug('reset due to dateFilter')
        await this.resetPaginatorConsumer()
      }
    },
    async resourceTypeFilter(this: any) {
      this.$console.debug('reset due to resourceTypeFilter')
      this.aggregations = {}
      this.facets.topics = []
      this.secondaryFilters.currentSortType = this.getCurrentSortType()
      await this.clearFilters(false)
    },
    async activeView(this: any) {
      this.aggregations = {}
      this.facets.topics = []
      this.secondaryFilters.currentSortType = this.getCurrentSortType()
      await this.clearFilters(false)
    },

    async searchSourceFilter(this: any, newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$console.debug('reset due to searchSourceFilter')
        await this.resetPaginatorConsumer()
      }
    },

    secondaryFilters: {
      async handler(this: any, newVal, oldVal) {
        if (newVal.currentSortType !== oldVal.currentSortType) {
          this.$console.debug('reset due to currentSortType')
          let sortBy = undefined
          if (
            (this.$route.query.query && newVal !== SORT_TYPE_DEFAULT.SEARCH) ||
            (!this.$route.query.query && newVal !== SORT_TYPE_DEFAULT.WORKSPACE)
          )
            sortBy = newVal.currentSortType
          this.$router.push({
            name: 'search',
            params: { ...this.$route.params },
            query: { ...this.$route.query, sortBy }
          })
          await this.resetPaginatorConsumer()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on('home', this.clearFilters)
  },
  beforeDestroy() {
    this.setSimilarQueries([])
    this.setExtractedEntities(undefined)
    this.setInsights(undefined)
    this.setSpellcheck('')
  },
  methods: {
    ...mapActions([
      'getSearchResourcesPaginator',
      'searchResources',
      'getSearchSubresourcePaginator',
      'resetDateFilters',
      'setExtractedEntities',
      'setInsights',
      'setContentClasses',
      'setFilterBlocks',
      'setFiltersLoading',
      'setActiveFilters',
      'setSimilarQueries',
      'setSpellcheck',
      'setSearchEnrichmentContext',
      'resetSearchInsights',
      'setSelectedFilter'
    ]),
    getCurrentSortType() {
      if (this.$route.params.tab === 'videos') return SORT_TYPE_DEFAULT.VIDEO
      if (this.$route.params.tab === 'people') return SORT_TYPE_DEFAULT.SEARCH
      if (
        this.$route.params.tab === 'slides' &&
        (this.$route.query?.view || '') === 'popular'
      )
        return SORT_TYPES_SLIDES_ENUM.POPULARITY
      if (!this.$route.query.sortBy)
        return this.$route.query.query
          ? SORT_TYPE_DEFAULT.SEARCH
          : SORT_TYPE_DEFAULT.WORKSPACE
      if (this.$route.query.query)
        return SORT_TYPE_OPTIONS.SEARCH.includes(this.$route.query.sortBy)
          ? this.$route.query.sortBy
          : SORT_TYPE_DEFAULT.SEARCH
      return SORT_TYPE_OPTIONS.WORKSPACE.includes(this.$route.query.sortBy)
        ? this.$route.query.sortBy
        : SORT_TYPE_DEFAULT.WORKSPACE
    },
    onReset() {},
    async clearFilters(this: any, redirect = true) {
      this.$console.debug('reset due to clear filters')
      const query = {
        query: this.$route.query.query,
        view: this.$route.query.view,
        sortBy: this.$route.query.sortBy
      }
      if (redirect) {
        this.$router.push({
          name: 'search',
          params: {
            ...this.$route.params
          },
          query
        })
      }
      this.resetSourcesAndDomains(false)
      this.resetDateFilters()
      await this.resetPaginatorConsumer()
      this.setActiveFilters([])
    },
    resetSourcesAndDomains(keepFilterBlocks = true) {
      this.facets = this.defaultFacets
      this.secondaryFilters = {
        currentSortType: this.getCurrentSortType(),
        dateFilter: DateFilters.ANYTIME
      }
      this.resetSearchInsights()
      this.$console.debug(
        'keepFilterBlocks',
        keepFilterBlocks,
        this.filterBlocks
      )
      if (!keepFilterBlocks) {
        this.filterBlocks = []
      }
      this.onReset()
    },

    activateFilter({ option, type }: { option: any; type: string }) {
      const idx = this.filterBlocks?.findIndex((e) => e.type === type)
      const optionsIdx = (idx === -1 ? [] : this.filterBlocks)[
        idx as number
      ].options.findIndex((e: any) => e.key === option.key)

      if (idx === -1 || optionsIdx === -1) {
        this.$console.debug(
          `Could not find ${
            idx === -1 ? 'type' : 'option in filter block'
          } for toggled filter, no filter will be activated`
        )
        return
      }

      let newOption: any =
        this.filterBlocks[idx as number].options[optionsIdx as number]
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: newOption.active
          ? searchTrigger.filter_disable
          : searchTrigger.filter,
        detail: type
      })
      this.filterBlocks[idx as number].options[optionsIdx as number] = {
        ...newOption,
        active: !newOption.active
      }
      this.setSelectedFilter({ type, option, active: !newOption.active })
    },

    clearFiltersByType(type: string) {
      const idx = this.filterBlocks?.findIndex((e) => e.type === type)
      if (idx === -1) {
        this.$console.debug(
          `Could not find type in filter block for cleared filter, no filter will be cleared`
        )
        return
      }
      this.filterBlocks[idx as number].options = this.filterBlocks[
        idx as number
      ].options.map((option: any) => ({ ...option, active: false }))
      this.setFilterInfo()
    },

    setFilterInfo(redirect = true) {
      const activeFilters = this.filterBlocks.reduce(
        (prev: any, block: any) => {
          return [
            ...prev,
            ...block.options.reduce((prevInner: any, entry: any) => {
              return [
                ...prevInner,
                ...(entry.active ? [{ ...entry, type: block.type }] : [])
              ]
            }, [])
          ]
        },
        []
      )

      this.setActiveFilters(activeFilters)
      if (redirect) {
        this.setFiltersInUrlQuery()
        this.filterBlocksChanged()
      }
    },

    setFiltersInUrlQuery() {
      const activeFilters = this.filterBlocks.filter(
        (f: any) => f.options.length > 0 && f.options.some((o: any) => o.active)
      )
      //TODO date filters will need additional work
      const query = {
        query: this.$route.query.query,
        view: this.$route.query.view,
        sortBy: this.$route.query.sortBy
      } as any
      for (let i = 0; i < activeFilters.length; i++) {
        const filter = activeFilters[i]
        const activeOptions = filter.options.filter((o: any) => o.active)

        query[filter.type] = activeOptions
          .map((ao: any) =>
            routeSafe(filter.type === 'authors' ? ao.key : ao.title ?? ao.key)
          )
          .join(',')
      }
      this.$router.push({
        name: 'search',
        params: {
          ...this.$route.params
        },
        query
      })
    },
    getFilterBlocks(): FilterBlockDefinition[] {
      const blocks = []
      this.$console.debug(
        'getting filter blocks',
        this.aggregations,
        this.filterBlocks
      )
      const facets = [
        'topics',
        'labels',
        'organisations',
        'industries',
        'authors'
      ]
      facets.forEach((facet) => {
        if (this.facets[facet]) {
          let activeFacetKeys =
            this.filterBlocks
              ?.find((x) => x.type === facet)
              ?.options.filter((x: any) => x.active)
              ?.map((x: any) => x.key) || []
          if (this.$route.query[facet]) {
            activeFacetKeys = filterFromRoute(this.$route, facet)
          }
          if (facet === 'authors') {
            activeFacetKeys = activeFacetKeys.map(
              (author_id: any) =>
                this.facets[facet]?.find(
                  (author: any) => author?.email === author_id
                )?.name
            )
          }

          const block = {
            title: facetToTitle[facet],
            type: facet,
            options: uniq([
              ...this.facets[facet]?.map((x: any) => x.name),
              ...activeFacetKeys,
              ...(facet === 'labels' ? ['No label'] : [])
            ]).map((x: any) => {
              const foundFacet: any =
                facet === 'labels' && x === 'No label'
                  ? {
                      uuid: 'none',
                      type: 'informative',
                      name: 'No label'
                    }
                  : this.facets[facet].find((y: any) => y.name === x) ||
                    this.filterBlocks
                      ?.find((y) => y.type === facet)
                      ?.options?.find((y: any) => y.key === x)
              const title =
                facet === 'authors' ? foundFacet?.name || foundFacet?.title : x
              const key =
                facet === 'authors'
                  ? foundFacet?.email
                  : foundFacet?.name || foundFacet?.title
              return {
                active: activeFacetKeys.includes(x),
                count: (foundFacet as any)?.count,
                key,
                title,
                aliases: foundFacet?.aliases || [],
                ...(facet === 'labels' && !!foundFacet
                  ? { labelType: foundFacet.type }
                  : {})
              }
            })
          }
          blocks.push(block)
        }
      })
      if (this.facets.mimetypes) {
        let mimetypeFromRoute = this.$route.query?.mimetypes?.split(',') ?? []
        blocks.push({
          title: 'File type',
          type: 'mimetypes',
          options: Object.keys(this.facets.mimetypes).map((x: any) => ({
            active: mimetypeFromRoute.includes(getTitleFromMimetype(x)),
            count: (this.facets.mimetypes?.[x] as any) || 0,
            key: x,
            title: getTitleFromMimetype(x)
          }))
        })
      }
      for (const aggKey of Object.keys(this.aggregations)) {
        if (aggKey === 'types') continue
        if (aggKey === 'bookmarks' || aggKey === 'editors') {
          blocks.push({
            title: facetToTitle[aggKey],
            type: aggKey,
            options: [
              { key: aggKey, active: false, count: this.aggregations[aggKey] }
            ]
          })
          continue
        }
        if (aggKey === 'content_classes')
          this.setContentClasses(this.aggregations[aggKey])

        let activeOptionKeys =
          this.filterBlocks
            ?.find((x) => x.type === aggKey)
            ?.options.filter((x: any) => x.active)
            ?.map((x: any) => x.key) || []
        if (this.$route.query[aggKey])
          activeOptionKeys = filterFromRoute(this.$route, aggKey)
        let options = uniq([
          ...Object.keys(this.aggregations[aggKey] || {}),
          ...activeOptionKeys
        ]).map((optionKey) => {
          return {
            key: optionKey,
            active: activeOptionKeys.includes(optionKey),
            count: this.aggregations?.[aggKey]?.[optionKey] || 0
          }
        })

        const dateFilter = [
          SearchFacet.created_dates,
          SearchFacet.modified_dates
        ].includes(aggKey)
        blocks.push({
          title: facetToTitle[aggKey],
          type: aggKey,
          options: dateFilter ? options.reverse() : options
        })
      }
      const filterBlocks = sortBy([...blocks], (x) =>
        defaultFacetOrdering.indexOf(x.type)
      )
      this.setFilterBlocks(filterBlocks)
      return filterBlocks
    },
    getFilters() {
      let filters = {} as any
      Object.keys(this.$route.query).forEach((key) => {
        const skipKeys = ['query', 'sortBy', 'view', 'act_as']
        if (!skipKeys.includes(key)) {
          if (key === 'mimetypes')
            return (filters[key] = filterFromRoute(this.$route, key).map(
              (value: string) => getMimetypeFromTitle(value)
            ))
          if (key === 'modified_dates' || key === 'created_dates')
            return (filters[key] = filterFromRoute(this.$route, key)
              .map((value: string) => this.getDateFilterFromYear(value))
              .flat())
          if (key === 'labels' && this.$route.query[key] === 'No label')
            return (filters[key] = ['None'])
          if (this.$route.query[key])
            filters[key] = filterFromRoute(this.$route, key)
        }
      })
      filters = this.getResourceTypeFilter(filters)
      return filters
    },
    getDateFilterFromYear(year: string) {
      const start = new Date(`Jan 1 ${year}`)
      const end = new Date(`Dec 31 ${year}`)
      return [this.formatDate(start), this.formatDate(end)]
    },
    getResourceTypeFilter(filters: any) {
      if (
        !['all', 'people', 'snippets', 'slides'].includes(
          (this as any).resourceTypeFilter
        )
      ) {
        filters['types'] = [(this as any).resourceTypeFilter]
      } else if ((this as any).resourceTypeFilter === 'all') {
        filters['types'] = ['document', 'presentation']
      }
      return filters
    },

    getDateRange(year: string) {
      const start_date = new Date(year)
      const end_date = moment(start_date).add(1, 'years').toDate()
      return [start_date, end_date].map((d) => moment(d).format('YYYY-MM-DD'))
    },
    formatDate(date: any) {
      return moment(date).format('YYYY-MM-DD')
    },

    async onDataReceive(data: any, pagesLoaded: number) {
      if (pagesLoaded === 1) {
        const {
          topics,
          experts,
          entities,
          mimetypes,
          extracted_entities,
          organisations,
          labels,
          industries,
          authors,
          insights,
          similar_queries,
          spellcheck,
          ...rest
        } = data.value.rawData
        // convert iso-codes
        let fullTextLanguages: any = {}
        if (rest.languages) {
          for (let lang of Object.keys(rest.languages)) {
            let count = rest.languages[lang]
            if (lang.includes('zh-')) {
              lang = 'zh'
            }
            let new_key_name = ISO6391.getName(lang)
            fullTextLanguages[new_key_name] = count
          }
        }
        rest.languages = fullTextLanguages
        const validSecondaryData = {} as any
        Object.keys(rest)
          .filter((key) => Object.keys(SearchFacet).includes(key))
          .forEach((key: string) => (validSecondaryData[key] = rest[key]))
        this.$console.debug('received data', data)
        this.loadingSecondaryData = true
        this.setFiltersLoading(true)
        this.setSimilarQueries(similar_queries)
        this.setSpellcheck(spellcheck)

        await this.getSecondaryData(
          topics,
          validSecondaryData,
          entities,
          experts,
          mimetypes,
          extracted_entities,
          organisations,
          labels,
          industries,
          authors,
          insights
        )
        this.loadingSecondaryData = false
        this.setFiltersLoading(false)
      }
    },

    getRequestData(extraData: object, extraParams: object): any {
      return {
        data: {
          sort_by: (ORDERING_MAP as any)[this.secondaryFilters.currentSortType],
          ...this.getFilters(),
          ...extraData,
          ...(this.activeView === 'myContent'
            ? { editors: [(this as any).currentWorkspaceMember.uuid] }
            : {}),
          ...(this.activeView === 'bookmarks'
            ? { bookmarks: [(this as any).currentWorkspaceMember.uuid] }
            : {}),
          ...(['year', 'month', 'sixMonths'].includes(this.activeView)
            ? { [SearchFacet.modified_dates]: this.getRecentViewFilter() }
            : {})
        },
        params: {
          ...(DateFilterToQP as any)[this.secondaryFilters.dateFilter]
        },
        extraParams: {
          ...extraParams,
          act_as: this.$route.query.act_as,
          included_facets: this.getFacets().join(' ')
        }
      }
    },

    async getSecondaryData(
      topics: any,
      aggregations: any,
      entities: any,
      experts: any,
      mimetypes: any,
      extractedEntities: any | undefined,
      organisations: any,
      labels: any,
      industries: any,
      authors: any,
      insights: any
    ) {
      this.$console.debug('getting secondary data')
      if (entities) {
        this.facets.entities = entities
      }
      for (const aggKey of Object.keys(aggregations)) {
        this.aggregations[aggKey] = aggregations[aggKey]
      }

      this.facets.experts = experts
      this.facets.extractedEntities = extractedEntities
      this.facets.mimetypes = mimetypes
      this.facets.organisations = organisations
      this.facets.labels = labels
      this.facets.industries = industries
      this.facets.authors = authors
      this.facets.topics = (topics || []) as Topic[]
      this.facets.insights = (insights || []) as any[]

      this.setExtractedEntities(extractedEntities)
      this.setInsights(insights)

      this.$set(this, 'filterBlocks', this.getFilterBlocks())
      this.setFilterInfo(false)
    },

    getExtraData() {
      return {}
    },

    getExtraParams() {
      return {}
    },

    getFacets() {
      switch ((this as any).resourceTypeFilter) {
        case 'slides':
          return this.subresourceFacets
        case 'video':
          return this.videoFacets
        case 'people':
          return this.peopleFacets
        case 'snippets':
          return this.snippetsFacets
        default:
          return this.resourceFacets
      }
    },

    hasEntity(type: string, key: string) {
      const entityObjs = this.facets.entities?.[type]
      if (!entityObjs) {
        return false
      }
      if (['topics', 'types', 'content_classes'].includes(type)) {
        return entityObjs.findIndex((x: any) => x.label === key) !== -1
      } else if (['industries', 'organisations', 'authors'].includes(type)) {
        return entityObjs.findIndex((x: any) => x.name === key) !== -1
      }
      return false
    },

    async getPaginator() {
      return await this.getSearchResourcesPaginator({
        ...this.getRequestData(
          this.getExtraData() || {},
          this.getExtraParams() || {}
        )
      })
    },

    getRecentViewFilter() {
      const today = new Date()
      const min = new Date()
      const delta: number = (
        {
          year: 365,
          month: 31,
          sixMonths: 183
        } as any
      )[this.activeView as any] as number
      min.setTime(today.getTime() - delta * 24 * 60 * 60 * 1000)
      return [this.formatDate(min), this.formatDate(today)]
    },

    async resetStore(this: any) {
      // @ts-ignore
      this[Mutations.RESET_RESOURCES_STATE]()
      await this.resetPaginatorConsumer()
    },

    filterBlocksChanged: debounce(async function (this: any) {
      this.$console.debug('reset due to filterBlocks')
      this.resetSourcesAndDomains(true)
      this.recreateFiltersOnPageLoad = true
      await this.resetPaginatorConsumer()
    }, 500)
  }
})

// IMPLEMENTATIONS

export const SecondarySearchMixin = mixins(
  PaginatorConsumer,
  BaseSecondarySearchMixin
).extend({
  methods: {
    async getSecondaryData(
      topics: any,
      aggregations: any,
      entities: any,
      experts: any,
      mimetypes: any,
      extractedEntities: any | undefined,
      organisations: any,
      labels: any,
      industries: any,
      authors: any,
      insights: any
    ) {
      this.$console.debug(`getting secondary data`)
      this.facets.entities = entities || undefined

      Object.keys(aggregations || {}).forEach(
        (aggKey) => (this.aggregations[aggKey] = aggregations[aggKey])
      )

      this.facets.extractedEntities = extractedEntities
      this.facets.experts = experts
      this.facets.mimetypes = mimetypes
      this.facets.organisations = organisations
      this.facets.labels = labels
      this.facets.industries = industries
      this.facets.authors = authors
      this.facets.insights = (insights || []) as any[]
      this.facets.topics = (topics || []) as Topic[]

      this.setExtractedEntities(extractedEntities)
      this.setInsights(insights)
      this.$set(this, 'filterBlocks', this.getFilterBlocks())
      this.setFilterInfo(false)
    },

    async getPaginator() {
      document.getElementById('scroll-container')?.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      return await this.getSearchResourcesPaginator({
        ...this.getRequestData(
          this.getExtraData() || {},
          this.getExtraParams() || {}
        ),
        resourceType: this.resourceTypeFilter
      })
    },

    getExtraData() {
      return {}
    },

    getExtraParams() {
      return {}
    }
  }
})
