<template>
  <div :class="{ pill: true, active }" @click="handleFilterClick()">
    <span class="pill-text">{{ name }}</span>
  </div>
</template>

<script>
import { filterFromRoute } from '@/util'

export default {
  name: 'PillFilter',
  props: {
    name: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    active() {
      return Object.keys(this.query).every((key) =>
        filterFromRoute(this.$route, key).includes(this.query[key])
      )
    }
  },
  methods: {
    handleFilterClick() {
      this.$emit('toggle', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.8rem;
  height: 2rem;
  background: #f1f2f3;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &-text {
    color: #8f9399;
  }

  &:hover {
    background: #ebebeb;
  }

  &.active {
    background: #303032;
    .pill-text {
      color: white;
    }
  }
}
</style>
