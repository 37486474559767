






















import allIntegrations from '@c/integrations'
import Vue from 'vue'

export default Vue.extend({
  name: 'ResourceCardBase',
  props: {
    source: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasImage() {
      return !!this.$slots.image
    },
    sourceColor() {
      return allIntegrations?.[this.source]?.defaultColor || '#F6F8FF'
    }
  }
})
