var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-base full ph-3",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        [
          _vm._t("title"),
          _c("div", { staticClass: "u-columns" }, [
            _c("div", { staticClass: "u-column mr-3" }, [_vm._t("default")], 2),
            _vm.hasImage
              ? _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [_vm._t("image")],
                  2
                )
              : _vm._e()
          ]),
          _vm._t("footer")
        ],
        2
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [_vm.$slots.underneath ? _c("div") : _vm._e(), _vm._t("underneath")],
        2
      ),
      _c("div", { staticClass: "bottom-line mh-3 mt-3" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }