var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pills-container", attrs: { id: "topfiltersuggestions" } },
    [
      _c(
        "div",
        {
          class: { "pills-content": true, scroll: _vm.scrollEnabled },
          attrs: { id: "pills-scroll" },
          on: {
            scroll: function($event) {
              return _vm.handleScroll()
            }
          }
        },
        [
          _vm._l(_vm.activeFilters, function(filter) {
            return _c("PillFilter", {
              key: filter.title,
              staticClass: "pill",
              attrs: { name: filter.title, query: filter.query },
              nativeOn: {
                click: function($event) {
                  return _vm.handleFilterClicked(filter.query)
                }
              }
            })
          }),
          _vm._l(_vm.topFilters, function(filter) {
            return _c("PillFilter", {
              key: filter.title,
              staticClass: "pill",
              attrs: { name: filter.title, query: filter.query },
              nativeOn: {
                click: function($event) {
                  return _vm.handleFilterClicked(filter.query)
                }
              }
            })
          })
        ],
        2
      ),
      _vm.scrollEnabled && _vm.arrowVisible.left
        ? _c("div", { staticClass: "pills-arrow left" }, [
            _c("img", {
              staticClass: "pills-arrow-img",
              attrs: { src: require("@/assets/icons/arrow-left.svg"), alt: "" },
              on: {
                click: function($event) {
                  return _vm.scroll(-1)
                }
              }
            })
          ])
        : _vm._e(),
      _vm.scrollEnabled && _vm.arrowVisible.right
        ? _c("div", { staticClass: "pills-arrow right" }, [
            _c("img", {
              staticClass: "pills-arrow-img",
              attrs: { src: require("@/assets/icons/arrow-left.svg") },
              on: {
                click: function($event) {
                  return _vm.scroll(1)
                }
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }