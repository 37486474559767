import Vue from 'vue'
import Console from '@c/console'

export default Vue.extend({
  data: function (): { loadingComponent?: any } {
    return {
      loadingComponent: undefined
    }
  },
  watch: {
    loading(val: boolean) {
      if (val) {
        //@ts-ignore
        const loadingContainer = this?.loadingContainer
        Console.debug('Loading activated, rendering on: ', loadingContainer)
        if (loadingContainer) {
          this.loadingComponent = this.$buefy.$loading.open({
            container: loadingContainer,
            isFullPage: false
          })
        }
      } else {
        if (this.loadingComponent !== undefined) {
          // this.loadingComponent.close()
          // this.loadingComponent = undefined
        }
      }
    }
  }
})
