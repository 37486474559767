var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageWithDoubleSidebar",
        {
          staticClass: "mt-1",
          attrs: { "class-default": "middle-search" },
          scopedSlots: _vm._u(
            [
              {
                key: "right",
                fn: function() {
                  return [_vm._t("right")]
                },
                proxy: true
              },
              {
                key: "left",
                fn: function() {
                  return undefined
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "div",
            [
              !_vm.loadingFilters
                ? _c(
                    "SearchHeader",
                    _vm._g(
                      {
                        attrs: {
                          "filter-options": _vm.filterOptions,
                          "extracted-entities": _vm.extractedEntities,
                          "filters-loading": _vm.loadingResources,
                          "result-amount": _vm.totalResources,
                          "result-amount-loading": _vm.loadingFilters,
                          "search-empty": _vm.searchEmpty,
                          "search-errored": _vm.searchErrored
                        }
                      },
                      _vm.$listeners
                    )
                  )
                : _vm._e(),
              _vm.loadingResources && !_vm.resultsResources.length
                ? _c(
                    "div",
                    { staticClass: "loading-container" },
                    [
                      _c("b-loading", {
                        attrs: { "is-full-page": false, "can-cancel": false },
                        model: {
                          value: _vm.loadingResources,
                          callback: function($$v) {
                            _vm.loadingResources = $$v
                          },
                          expression: "loadingResources"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resultsResources.length > 0
                ? _c(
                    "ResourceGrid",
                    _vm._g(
                      {
                        attrs: {
                          resources: _vm.resultsResources,
                          "visualisation-type": _vm.viewType,
                          loading: _vm.loadingResources,
                          workspaces: _vm.workspaces,
                          "current-user": _vm.currentUser,
                          "all-loaded": _vm.allLoaded,
                          "show-topics": true,
                          "total-resource-amount": _vm.totalResources,
                          "loading-filters": _vm.loadingFilters,
                          sort: _vm.sort,
                          "show-header": true,
                          "extracted-entities": _vm.extractedEntities,
                          "search-request-props": _vm.searchRequestProps
                        },
                        on: {
                          loadMore: function($event) {
                            return _vm.$emit("loadMoreResources")
                          },
                          sortingChanged: function(x) {
                            return _vm.$emit("sortingChanged", x)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "before-resources",
                              fn: function() {
                                return [_vm._t("top-blocks")]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      _vm.$listeners
                    )
                  )
                : _vm._e(),
              _vm.searchEmpty
                ? _c("SearchEmptyState", {
                    attrs: {
                      "search-query": _vm.searchQuery,
                      types: _vm.types,
                      "search-request-props": _vm.searchRequestProps
                    }
                  })
                : _vm._e(),
              _vm.searchErrored ? _c("SearchErrorState") : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }