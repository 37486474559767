import { render, staticRenderFns } from "./UserModal.vue?vue&type=template&id=a71106e6&scoped=true&"
import script from "./UserModal.vue?vue&type=script&lang=ts&"
export * from "./UserModal.vue?vue&type=script&lang=ts&"
import style0 from "./UserModal.vue?vue&type=style&index=0&id=a71106e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a71106e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a71106e6')) {
      api.createRecord('a71106e6', component.options)
    } else {
      api.reload('a71106e6', component.options)
    }
    module.hot.accept("./UserModal.vue?vue&type=template&id=a71106e6&scoped=true&", function () {
      api.rerender('a71106e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/shared/molecules/object-visualisations/user/UserModal.vue"
export default component.exports