<template>
  <transition name="fade">
    <div
      v-show="isHovering"
      class="info"
      @mouseenter="onInfoMouseEnter"
      @mouseleave="onInfoMouseLeave"
    >
      <b-icon
        type="is-white"
        size="is-small"
        icon="information-variant"
      ></b-icon>
      <transition name="fade">
        <div
          v-show="showInfo"
          v-if="references"
          class="card info-card pa-4"
          :class="cardPositionClass"
          :style="`min-width: ${width}px; max-width:: ${width}px;width: ${width}px`"
          @click.stop
        >
          <h1 class="mb-3">
            Appears in {{ totalReferences }}
            {{ totalReferences === 1 ? 'document' : 'documents' }}
          </h1>
          <div v-for="(reference, index) in referencesShown" :key="index">
            <div class="reference mt-2">
              <DocumentTag
                class="u-column is-narrow mr-3"
                :mimetype="reference.resource.integrationfile.mimetype"
              />
              <span
                class="link"
                @click.stop="onResourceRedirect(reference.resource)"
              >
                {{ reference.resource.title }}
              </span>
            </div>
          </div>
          <div
            v-if="totalReferences > 3"
            class="showmore link blue mb-4 ml-3"
            @click="showMore = !showMore"
          >
            {{ showMore ? 'Show less' : 'Show more' }}
          </div>
          <div v-if="showTags" class="mt-3">
            <h1>{{ tagsHeader }}</h1>
            <ResourceTagList :tags="tags" class="mt-3" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import {
  downloadSignedUrl,
  getDocumentDownloadLink
} from '@/services/presentationService'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { mapGetters } from 'vuex'
import ResourceTagList from './ResourceTagList.vue'

export default {
  name: 'InfoHover',
  components: {
    DocumentTag,
    ResourceTagList
  },
  props: {
    isHovering: {
      type: Boolean,
      default: false
    },
    references: {
      type: Array,
      default: () => []
    },
    totalReferences: {
      type: Number,
      default: 0
    },
    showTags: {
      type: Boolean,
      default: false
    },
    tagsTitle: {
      type: String,
      default: undefined
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showInfo: false,
      mouseY: 0,
      width: 0,
      showMore: false
    }
  },
  computed: {
    ...mapGetters(['windowWidth', 'downloadFilesEnabled']),
    tagsHeader() {
      if (this.tagsTitle) return this.tagsTitle
      return 'Tags'
    },
    cardPositionClass() {
      if (this.mouseY < 0.5) return 'card-down'
      return 'card-up'
    },
    referencesShown() {
      return this.showMore ? this.references : this.references.slice(0, 3)
    }
  },
  watch: {
    windowWidth: {
      immediate: true,
      handler() {
        this.width = this.clamp(300, 500, this.windowWidth * 0.25)
      }
    }
  },
  methods: {
    clamp(min, max, value) {
      if (value < min) return min
      if (value > max) return max
      return value
    },
    async onInfoMouseEnter(e) {
      this.mouseY = e.clientY / window.innerHeight
      this.showInfo = true
      this.$emit('hover')
    },
    onInfoMouseLeave() {
      this.showInfo = false
    },
    onResourceRedirect(resource) {
      if (this.downloadFilesEnabled) {
        this.downloadResource(resource)
      } else {
        const win = window.open(resource.url, '_blank')
        win?.focus()
        this.$emit('resource-redirect', resource.uuid)
      }
    },
    async downloadResource(resource) {
      const downloadData = await getDocumentDownloadLink(
        this.$route.params.workspace_id,
        resource.uuid,
        this.$route.query?.act_as
      )
      downloadSignedUrl(downloadData['signed_url'], downloadData['name'])
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.info {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 3;
  border-radius: 180px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .info-card {
    position: absolute;
    left: 0;
    z-index: 999;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    cursor: default;
    &.card-up {
      bottom: 30px;
    }
    &.card-down {
      top: 30px;
    }

    h1 {
      font-size: 20px;
    }

    .reference {
      display: flex;
      .link {
        color: $blue;
        font-weight: bolder;
        word-break: break-word;
        &:hover {
          color: $blue;
          text-decoration: underline;
        }
      }
    }
  }
}

.showmore {
  width: fit-content;
}
</style>
