
















import UserAvatar from '@c/shared/molecules/object-visualisations/user/UserAvatar.vue'

export default {
  name: 'UserModalHeader',

  components: {
    UserAvatar
  },

  props: {
    avatar: {
      type: String,
      default: ''
    },

    fullName: {
      type: String as () => string,
      required: true
    },

    jobTitle: {
      type: String as () => string,
      default: 'Workspace Member'
    }
  },
  computed: {}
}
