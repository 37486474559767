var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.members && _vm.members.length > 0
    ? _c(
        "ResultBlock",
        {
          attrs: {
            "block-title": "People",
            "block-icon": "account-group",
            "result-count": _vm.members.length || 0,
            "has-border": true
          }
        },
        [
          _c("h2", { staticClass: "block-header" }, [
            _vm._v("Ask these people for help")
          ]),
          _c("div", { staticClass: "loading-container mt-3" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "ph-5 pb-3" },
                  [_c("PeopleBlockLoading")],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "u-columns is-multiline" },
                  [
                    _vm._l(
                      _vm.mappedMembers.slice(0, _vm.shownMembers),
                      function(member) {
                        return _c(
                          "div",
                          {
                            key: member.uuid,
                            staticClass:
                              "\n          member-entry\n          u-column\n          is-12-desktop\n          is-12-widescreen\n          is-12-fullhd\n          is-12-tablet\n          is-12-mobile\n        ",
                            style: _vm.memberStyle(member)
                          },
                          [
                            _c(
                              _vm.userAvatarComponent,
                              {
                                tag: "component",
                                attrs: {
                                  avatar: member.avatar,
                                  title: _vm.memberAvatarTitle(member),
                                  subtitle: _vm.memberAvatarSubtitle(member),
                                  size: 50
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectMember(member)
                                  }
                                }
                              },
                              [
                                _c("InitialsOrb", {
                                  attrs: {
                                    initials: _vm.$umodel.initials(member),
                                    color: _vm.$umodel.user_color(member),
                                    "avatar-height": 50,
                                    "avatar-width": 50
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    _vm.members.length > _vm.shownMembers
                      ? _c(
                          "b-button",
                          {
                            staticClass: "u-btn bare",
                            attrs: { expanded: "" },
                            on: {
                              click: function($event) {
                                _vm.shownMembers += _vm.pageSize
                              }
                            }
                          },
                          [
                            _c("p", { staticClass: "link blue bold" }, [
                              _vm._v("Show more")
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
          ]),
          _vm.showModal
            ? _c("b-modal", {
                attrs: { "trap-focus": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("UserModal", {
                            attrs: { user: _vm.selectedMember }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1818411421
                ),
                model: {
                  value: _vm.isModalActive,
                  callback: function($$v) {
                    _vm.isModalActive = $$v
                  },
                  expression: "isModalActive"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }