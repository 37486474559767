
































import allIntegrations, { Integration } from '@c/integrations'
import { IntegrationData } from '@c/models/Resource'
import Vue from 'vue'
export default Vue.extend({
  name: 'IntegrationPlaceholder',
  props: {
    integrationData: {
      type: Object as () => IntegrationData,
      required: true
    }
  },
  computed: {
    integration(): Integration {
      return allIntegrations[this.integrationData.integration]
    },
    integrationImage(): string {
      return (this.integration as Integration).image
    }
  }
})
