var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "user-container": true,
        "scrollbar-visible": _vm.scrolling,
        "pa-5": true
      },
      on: { scroll: _vm.handleScrolling }
    },
    [
      _c(
        "div",
        { staticClass: "pb-4" },
        [
          _c("UserModalHeader", {
            attrs: { avatar: _vm.user.avatar, "full-name": _vm.fullName }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "activity pb-4" },
        [
          _c("ActivityGrid", {
            attrs: { activity: _vm.user.activity, big: true }
          })
        ],
        1
      ),
      _vm.showTopics
        ? _c(
            "div",
            { staticClass: "tag-list" },
            [_c("UserTopicList", { attrs: { topics: [_vm.user.topic] } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }