var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "u-columns pt-6",
      class: _vm.classLeft,
      style: _vm.styleLeft
    },
    [
      !_vm.noLeft && _vm.hasLeft
        ? _c("div", { staticClass: "u-column is-narrow" }, [_vm._t("left")], 2)
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "u-column",
          class: _vm.classDefault,
          style: _vm.styleDefault
        },
        [_vm._t("default")],
        2
      ),
      !_vm.noRight && _vm.hasRight
        ? _c(
            "div",
            {
              staticClass: "u-column",
              class: _vm.classRight,
              style: _vm.styleRight
            },
            [_vm._t("right")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }