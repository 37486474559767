export function getTitleFromMimetype(mimetype) {
  const mapping = {
    'application/vnd.google-apps.document': 'google docs',
    'application/vnd.google-apps.presentation': 'google slides',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'pptx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.oasis.opendocument.presentation': 'odp',
    'application/vnd.oasis.opendocument.text': 'odt'
  }
  return mapping[mimetype] || ''
}

export function getMimetypeFromTitle(title) {
  const mapping = {
    'google docs': 'application/vnd.google-apps.document',
    'google slides': 'application/vnd.google-apps.presentation',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ppt: 'application/vnd.ms-powerpoint',
    odp: 'application/vnd.oasis.opendocument.presentation',
    odt: 'application/vnd.oasis.opendocument.text'
  }
  return mapping[title] || ''
}
