



















import Vue from 'vue'

export default Vue.extend({
  name: 'PageWithDoubleSidebar',

  props: {
    classDefault: {
      type: [Object, String],
      default: () => ({})
    },

    classLeft: {
      type: [Object, String],
      default: () => ({})
    },

    classRight: {
      type: [Object, String],
      default: () => ({})
    },

    styleDefault: {
      type: [Object, String],
      default: () => ({})
    },

    styleLeft: {
      type: [Object, String],
      default: () => ({})
    },

    styleRight: {
      type: [Object, String],
      default: () => ({})
    },

    noRight: {
      type: Boolean,
      default: false
    },

    noLeft: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasLeft(): Boolean {
      return !!this.$slots.left
    },

    hasRight(): Boolean {
      return !!this.$slots.right
    }
  }
})
