






























































import { Expert } from '@c/models/User'
import WorkspaceMember from '@c/models/WorkspaceMember'
import PeopleBlockLoading from '@c/shared/molecules/loading/resources/PeopleBlockLoading.vue'
import InitialsOrb from '@c/shared/molecules/object-visualisations/user/InitialsOrb.vue'
import UserModal from '@c/shared/molecules/object-visualisations/user/UserModal.vue'
import Vue from 'vue'
import { mapState } from 'vuex'
import HorizontalUserAvatar from '../../../../shared/molecules/object-visualisations/user/HorizontalUserAvatar.vue'
import VerticalUserAvatar from '../../../../shared/molecules/object-visualisations/user/VerticalUserAvatar.vue'
import ResultBlock from '../../molecules/ResultBlock.vue'

export default Vue.extend({
  components: {
    ResultBlock,
    PeopleBlockLoading,
    HorizontalUserAvatar,
    VerticalUserAvatar,
    UserModal,
    InitialsOrb
  },

  props: {
    members: {
      type: Array as () => WorkspaceMember[] | Expert[],
      default: undefined
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pageSize: 3,
      shownMembers: 3,

      isModalActive: false,
      selectedMember: null
    }
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.webapp.isMobile
    }),

    mappedMembers() {
      return this.members
        .map((m) =>
          m.user
            ? {
                ...m.user,
                ...(m.count ? { doc_count: m.count } : {}),
                ...(m.taglines ? { taglines: m.taglines } : {})
              }
            : m
        )
        .sort((a, b) => {
          return this.memberActive(b) - this.memberActive(a)
        })
    },

    userAvatarComponent() {
      return this.isMobile ? VerticalUserAvatar : HorizontalUserAvatar
    },

    showModal() {
      return false
    }
  },

  methods: {
    selectMember(member: Expert) {
      if (!this.showModal) return

      this.selectedMember = member
      this.isModalActive = true
    },

    memberAvatarTitle(member: Expert) {
      return this.$umodel.full_name(member)
    },

    memberAvatarSubtitle(member: WorkspaceMember | Expert) {
      return member.taglines
        ? member.taglines
        : `${member.doc_count} Contribution${member.doc_count === 1 ? '' : 's'}`
    },

    memberActive(member: WorkspaceMember) {
      return member.status ? member.status.toUpperCase() !== 'INACTIVE' : true
    },

    memberStyle(member: WorkspaceMember) {
      const inactive = !this.memberActive(member)
      const filter = inactive ? 'grayscale(80%)' : undefined
      const opacity = inactive ? 0.5 : 1
      return {
        filter,
        '-webkit-filter': filter,
        opacity
      }
    }
  }
})
