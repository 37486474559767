<template>
  <div id="sheetsblock" class="discover">
    <p id="sheetsblocktitle" class="header semi-bold xs mb-4 row">
      <img src="@/assets/icons/sheet.svg" alt="" class="header-img mr-3" />
      {{ header }}
    </p>
    <div v-if="!allImagesLoaded" class="discover-loading row full-width">
      <b-skeleton v-for="el in 3" :key="el" height="100%" />
    </div>
    <div
      class="discover-sheets"
      :class="{
        'discover-sheets-grid': sheetsData && sheetsData.length === pageSize
      }"
    >
      <div
        v-for="(sheet, idx) in sheetsData"
        :key="sheet.uuid"
        class="discover-sheets-item"
      >
        <ResourceCard
          :resource="sheet"
          :rank="idx + 1"
          location="sheetsblock"
          :search-id="searchId"
          :class="{ hidden: !allImagesLoaded }"
          @load="imagesLoaded += 1"
          @cantLoad="imagesErrored += 1"
        />
      </div>
    </div>
    <div class="discover-more link mt-4" @click="showMoreSheets">
      <p
        v-if="!sheetsData.length || sheetsData.length === pageSize"
        class="link blue"
      >
        See more <span class="link bold">sheets</span>
      </p>
      <p v-else class="link blue">
        Go to the <span class="link bold">sheets tab</span>
      </p>
      <img
        src="@/assets/icons/arrow-left.svg"
        alt=""
        class="discover-more-img"
      />
    </div>
  </div>
</template>

<script>
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { SearchFacet } from 'umanai-vuex/src/store/modules/resources.js'
import { mapActions, mapGetters } from 'vuex'
import ResourceCard from '../ResourceCard.vue'

export default {
  name: 'SheetsDiscover',
  components: { ResourceCard },
  props: {
    searchRequestProps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    sheetsData: [],
    pageSize: 3,
    imagesLoaded: 0,
    imagesErrored: 0,
    searchId: ''
  }),
  computed: {
    ...mapGetters(['searchEnrichmentContext']),
    allImagesLoaded() {
      return (
        this.imagesLoaded + this.imagesErrored ===
        (this.sheetsData?.length || this.pageSize)
      )
    },
    header() {
      return this.$route.query.query
        ? `Sheets for “${this.$route.query.query}”`
        : 'Sheets'
    }
  },
  async mounted() {
    try {
      const { response_data } = await this.getSearchResourcesPage({
        ...this.searchRequestProps,
        data: {
          ...this.searchRequestProps.data,
          ...this.searchEnrichmentContext,
          tab: 'all',
          types: ['sheet']
        },
        extraParams: {
          ...this.searchRequestProps.extraParams,
          page_size: this.pageSize,
          included_facets: [
            SearchFacet.types,
            SearchFacet.content_classes,
            SearchFacet.topics,
            SearchFacet.organisations,
            SearchFacet.industries,
            SearchFacet.created_dates,
            SearchFacet.modified_dates,
            SearchFacet.languages,
            SearchFacet.authors,
            SearchFacet.mimetypes,
            SearchFacet.labels,
            SearchFacet.bookmarks,
            SearchFacet.editors,
            SearchFacet.file_locations
          ].join(' ')
        },
        resourceType: 'sheet'
      })
      this.$emit('completed')
      if ((response_data.resources?.length || 0) === 0) {
        this.$emit('hide')
      } else {
        this.sheetsData = response_data.resources || []
      }
      this.searchId =
        response_data?.search_id || response_data?.results?.search_id || ''
    } catch (e) {
      this.$emit('hide')
    }
  },
  methods: {
    ...mapActions(['getSearchResourcesPage', 'setSearchEnrichmentContext']),
    showMoreSheets() {
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.slideblock
      })
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          tab: 'sheets'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.discover {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  border: 1px solid rgba(#000, 8%);
  border-radius: 8px;
  background: #f9f9fa;

  &-sheets {
    width: 100%;
    max-width: 1500px;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    &.discover-sheets-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &:not(.discover-sheets-grid) {
      display: flex;
      flex-flow: row nowrap;
      & > .discover-sheets-item {
        max-width: 450px;
      }
    }
  }

  &-more {
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 0.5rem;

    &-img {
      filter: invert(22%) sepia(96%) saturate(2594%) hue-rotate(218deg)
        brightness(99%) contrast(99%);
      transform: rotate(180deg);
      height: 1rem;
    }
  }
  &.header {
    color: #303032;

    .header-img {
      height: 1.2rem;
    }
  }

  &-loading {
    gap: 0.5rem;
  }
}

.b-skeleton {
  margin-top: 0 !important;
  aspect-ratio: 16 / 9;
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
