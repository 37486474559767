








































































































































































































import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import {
  addBookmarkToResource,
  deleteBookmarkFromResource
} from '@/services/bookmarkService'
import {
  eventContext,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent,
  sendInspectEvent
} from '@/services/feedbackService'
import {
  highlightResource,
  removeHighlightFromResource
} from '@/services/highlightService'
import { getDocumentDownloadLink } from '@/services/presentationService'
import HighlightHoverInfo from '@/views-v2/home/highlight/HighlightHoverInfo.vue'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import { HighlightedText } from '@c/mixins/HighlightingMixin'
import { Activity, IntegrationData, SubResource } from '@c/models/Resource'
import ResourceCardBase from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceCardBase.vue'
import SubResourceList from '@c/shared/molecules/object-visualisations/resource/subcomponents/SubResourceList.vue'
import HighlightedTextComponent from '@c/shared/molecules/structural/blocks/HighlightedTextComponent.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { isEmpty } from 'lodash'
import Vue, { PropType } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  Mimetypes,
  SubResourceUnits,
  downloadableMimetypes,
  mimetypeToType,
  typeToUnit
} from '../../../../mimetypes'
import MissingWords from './subcomponents/MissingWords.vue'
import QualityLabels from './subcomponents/QualityLabels.vue'
import ResourceAssistMenu from './subcomponents/ResourceAssistMenu.vue'
import ResourceCollectMenu from './subcomponents/ResourceCollectMenu.vue'
import ResourceInfo from './subcomponents/ResourceInfo.vue'
import ResourceTagList from './subcomponents/ResourceTagList.vue'
import Button from '@c/library/Button.vue'
// import { typeToMimetype } from '../../../../mimetypes'

export default Vue.extend({
  name: 'ResourceListItem',

  components: {
    ResourceCardBase,
    ResourceInfo,
    HighlightedTextComponent,
    DocumentTag,
    SubResourceList,
    ResourceTagList,
    QualityLabels,
    MissingWords,
    ResourceAssistMenu,
    HighlightHoverInfo,
    ResourceCollectMenu,
    UploadDownloadDropdown,
    Button
  },

  mixins: [PaginatorConsumer],

  props: {
    noSubresources: {
      type: Boolean,
      default: false
    },

    noIntegrationPathTooltip: {
      type: Boolean,
      default: false
    },

    link: {
      type: [String, Array],
      required: true
    },

    canAdd: {
      type: Boolean,
      default: true
    },

    canDelete: {
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: undefined
    },

    resourceIntegration: {
      type: Object as () => IntegrationData,
      default: null
    },

    blobMimetype: {
      type: String,
      default: ''
    },

    title: {
      type: [String, Array] as PropType<string | HighlightedText[]>,
      required: true
    },

    description: {
      type: [String, Array] as PropType<string | HighlightedText[]>,
      default: () => [] as HighlightedText[]
    },

    uuid: {
      type: String,
      default: undefined
    },

    viewsActive: {
      type: Boolean,
      default: false
    },

    savesActive: {
      type: Boolean,
      default: false
    },

    workspaces: {
      type: Array as () => Workspace[],
      default: function(): Workspace[] {
        return []
      }
    },

    isDense: {
      type: Boolean,
      default: false
    },

    showTopics: {
      type: Boolean,
      default: true
    },

    subresources: {
      type: Array as () => SubResource[],
      default: () => []
    },

    lastUpdated: {
      type: Object,
      default: undefined
    },

    contentClass: {
      type: String as () => string,
      default: undefined
    },

    resourceType: {
      type: String as () => string,
      default: undefined
    },

    organisations: {
      type: Array as () => string[],
      default: undefined
    },

    topics: {
      type: Object as () => object,
      default: undefined
    },

    industries: {
      type: Array as () => string[],
      default: undefined
    },

    activity: {
      type: Array as () => Activity[],
      default: []
    },

    similarPages: {
      type: Array,
      default: () => []
    },
    label: {
      type: Object,
      default: () => {}
    },
    missingWords: {
      type: Array,
      default: () => []
    },
    bookmark: {
      type: [String, null],
      default: null
    },
    highlight: {
      type: [Object, undefined],
      default: undefined
    },
    traceId: {
      type: String,
      required: true
    },
    rank: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      hover: false,
      intersectionsHovered: false,
      hideSubresources: false,
      selectedModalPage: -1,
      relevantSubresources: [],
      requestFallback: false,
      thumbnailFallback: false,
      isBookmarked: false,
      highlightValue: undefined,
      highlightHovered: false,
      modalScroll: ''
    }
  },

  computed: {
    ...mapState({
      isMobile: state => state.webapp.isMobile
    }),
    ...mapGetters([
      'lastSearchId',
      'highlightsEnabled',
      'downloadFilesEnabled',
      'showCollectSlides',
      'canWriteLabels'
    ]),

    mimetype() {
      return (this.resourceIntegration || { mimetype: this.blobMimetype })
        .mimetype
    },

    isDownloadableMimetype() {
      return downloadableMimetypes.includes(this.mimetype)
    },

    showSubresources() {
      return (
        ((this.subresources &&
          this.subresources.filter(x => x.preview).length > 0) ||
          this.image) &&
        !this.hideSubresources
      )
    },

    concattedTitle() {
      return typeof this.title === 'string' || this.title instanceof String
        ? this.title
        : this.title.map(x => x.text).join('')
    },

    fallbackUsed() {
      return this.requestFallback || this.thumbnailFallback
    },

    inferredMimetype() {
      return mimetypeToType[this.blobMimetype]
    },

    subResourceUnit(): SubResourceUnits {
      return typeToUnit[this.inferredMimetype] || 'page'
    },

    selectableMimeType(): boolean {
      return [Mimetypes.PPTX, Mimetypes.PPT, Mimetypes.GOOGLE_SLIDES].includes(
        this.inferredMimetype
      )
    },

    aggregatedTags() {
      const resType =
        (this.resourceType || '') === '' ? [] : [this.resourceType]
      const cClass =
        this.contentClass && this.contentClass !== '' ? [this.contentClass] : []
      const orgs = (this.organisations || []).map(x => x.name)
      const topics = (this.topics.labels || []).map(x => x.name)
      const industries = (this.industries || []).map(x => x.name)
      return [...resType, ...cClass, ...orgs, ...industries, ...topics]
    }
  },
  watch: {
    async workspaces(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.resetPaginatorConsumer()
        await this.resetRelevantSubresources()
      }
    }
  },
  async mounted() {
    this.resetRelevantSubresources(true)
    this.isBookmarked = !!this.bookmark
    this.highlightValue = this.highlight
  },

  methods: {
    ...mapActions(['setResourceModal']),
    async getDocumentDownloadProps() {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        this.uuid,
        this.$route.query?.act_as
      )
    },
    async toggleBookmark() {
      try {
        if (this.isBookmarked) {
          await deleteBookmarkFromResource(
            this.$route.params.workspace_id,
            this.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = false
        } else {
          await addBookmarkToResource(
            this.$route.params.workspace_id,
            this.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = true
        }
      } catch (error) {
        this.$toast.error(
          error,
          `${this.isBookmarked ? 'removing' : 'adding'} bookmark`
        )
      }
    },
    async toggleHighlight() {
      if (!this.canWriteLabels) return
      try {
        if (!isEmpty(this.highlightValue)) {
          await removeHighlightFromResource(
            this.$route.params.workspace_id,
            this.uuid,
            this.$route.query.act_as
          )
          this.highlightValue = undefined
        } else {
          this.highlightValue = await highlightResource(
            this.$route.params.workspace_id,
            this.uuid,
            this.$route.query.act_as
          )
        }
      } catch (e) {
        this.$console.debug(
          'Something went wrong when when changing highlight',
          e
        )
        this.$toast.error(e, 'changing the highlight')
      }
    },
    async onSlideClick(slidePage) {
      sendInspectEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.traceId,
        subresource_trace_id: this.subresources.find(
          sr => sr.page === slidePage
        ).trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        rank: this.rank,
        comment: 'opening resource modal with slide click'
      })
      this.showResourceModal(slidePage)
    },
    async onTitleClick() {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.traceId,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        comment: 'from search results',
        rank: this.rank
      })
      this.$emit('gotoResource')
    },
    async onPathClick() {
      sendFolderClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.traceId,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        comment: 'from search results',
        rank: this.rank
      })
    },

    showResourceModal(selectedPage, scroll = '') {
      this.hover = false
      this.selectedModalPage = selectedPage
      this.modalScroll = scroll
      this.setResourceModal({
        resource_id: this.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: this.selectedModalPage,
          subresourcesRelevant: !this.fallbackUsed,
          selectable: this.selectableMimeType,
          scrollTo: this.modalScroll,
          similarPages: this.similarPages,
          rank: this.rank,
          resourceType: this.resourceType,
          resourceTraceId: this.traceId
        },
        buefy_modal_props: {}
      })
    },

    async resetRelevantSubresources(initialize = false) {
      if (this.subresources && this.subresources.length > 0) {
        this.relevantSubresources = this.subresources
      } else {
        // Initialize paginator manually and fetch first 10 subresources
        if (initialize) {
          this.settingUpPaginator = true
          await this.setupPaginatorConsumer()
          this.settingUpPaginator = false
        }

        if (this.paginatorData && this.paginatorData.length > 0) {
          this.relevantSubresources = this.paginatorData
          this.requestFallback = true
        } else {
          this.relevantSubresources = [
            {
              preview: this.image,
              page: 1
            }
          ]
          this.thumbnailFallback = true
        }
      }
    }
  }
})
