var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-loading-container" },
    [
      _c("b-skeleton", { attrs: { height: "100%", width: "20%" } }),
      _c(
        "div",
        { staticClass: "resource-loading-subresources" },
        [
          _c("b-skeleton", { attrs: { height: "100%", width: "100%" } }),
          _c("b-skeleton", { attrs: { height: "100%", width: "100%" } }),
          _c("b-skeleton", { attrs: { height: "100%", width: "100%" } })
        ],
        1
      ),
      _c("b-skeleton", { attrs: { height: "100%", width: "40%" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }