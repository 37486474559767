var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subresource-loading-container" },
    [
      _c("b-skeleton", {
        staticClass: "test",
        staticStyle: { "margin-top": "0" },
        attrs: { height: "100%", width: "100%" }
      }),
      _c("b-skeleton", {
        staticClass: "test",
        staticStyle: { "margin-top": "0" },
        attrs: { height: "100%", width: "100%" }
      }),
      _c("b-skeleton", {
        staticClass: "test",
        staticStyle: { "margin-top": "0" },
        attrs: { height: "100%", width: "100%" }
      }),
      _c("b-skeleton", {
        staticClass: "test",
        staticStyle: { "margin-top": "0" },
        attrs: { height: "100%", width: "100%" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }