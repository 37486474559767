























import User, { Expert } from '@c/models/User'
import UserTopicList from '@c/shared/molecules/object-visualisations/user/UserTopicList.vue'
import UserModalHeader from '@c/shared/molecules/object-visualisations/user/UserModalHeader.vue'
import ActivityGrid from '@c/shared/molecules/object-visualisations/resource/subcomponents/activity/ActivityGrid.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'UserModal',
  components: {
    UserModalHeader,
    UserTopicList,
    ActivityGrid
  },
  props: {
    user: {
      type: Object as () => User | Expert,
      required: true
    }
  },
  data() {
    return {
      scrolling: false,
      scrollingTimer: null as unknown as NodeJS.Timeout
    }
  },
  computed: {
    fullName() {
      return this.$umodel.full_name(this.user)
    },

    showTopics() {
      return process.env.NODE_ENV !== 'production'
    }
  },
  destroyed() {
    clearTimeout(this.scrollingTimer)
  },
  methods: {
    handleScrolling() {
      this.scrolling = true
      this.scrollingTimer = setTimeout(() => {
        this.scrolling = false
      }, 1000)
    }
  }
})
