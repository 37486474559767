<template>
  <div
    :class="{
      'u-columns': true,
      'is-vcentered': true,
      'pa-2': true,
      'is-clickable': showModal,
      selectable: showModal
    }"
    @click="$emit('click', $event)"
  >
    <div class="u-column u-columns center is-narrow is-vcentered">
      <UserAvatar :avatar="avatar" :width="size" :height="size" :orb="orb">
        <slot></slot>
        <template v-slot:orb>
          <slot name="orb"></slot>
        </template>
      </UserAvatar>
    </div>
    <div class="u-column hor-avatar-text ml-4">
      <slot name="title">
        <p class="body xs has-text-left mb-2 user-title">{{ title }}</p>
      </slot>
      <slot name="subtitle">
        <p
          v-for="(sub, i) in subtitles"
          :key="i"
          class="body has-text-left body-text xxs user-subtitle"
        >
          {{ sub }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import UserAvatar from './UserAvatar'

export default {
  name: 'HorizontalUserAvatar',
  components: { UserAvatar },

  props: {
    size: {
      type: Number,
      default: 32
    },

    avatar: {
      type: String,
      default: undefined
    },

    title: {
      type: String,
      default: undefined
    },

    subtitle: {
      type: [String, Array],
      default: undefined
    },

    orb: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subtitles() {
      return Array.isArray(this.subtitle) ? this.subtitle : [this.subtitle]
    },

    showModal() {
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.selectable:hover {
  background: rgba(221, 232, 226, 0.5);
}

.hor-avatar-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-title,
.user-subtitle {
  font-weight: bold;
}
</style>
