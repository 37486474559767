<template>
  <div class="empty">
    <img :src="icon" alt="" class="empty-icon" />
    <!-- eslint-disable-next-line -->
    <p class="empty-title" v-html="title"></p>
    <p class="empty-subtitle">
      {{ subtitle }}
    </p>
    <div
      v-if="Object.keys(types).length > 0 || slideCount > 0"
      class="empty-tabs"
    >
      <p class="empty-tabs-header">Or check out these results in another tab</p>
      <p v-for="(count, type) in types" :key="type" class="empty-tabs-tab">
        <span class="bold"
          >{{ count }} {{ type }}{{ count === 1 ? '' : 's' }}</span
        >
        in the
        <span class="empty-tabs-tab-link" @click="gotoTab(type)"
          >{{ typeToTab[type] }} tab →</span
        >
      </p>
      <p v-if="slideCount > 0" class="empty-tabs-tab">
        <span class="bold"
          >{{ slideCount }} slide{{ slideCount === 1 ? '' : 's' }}</span
        >
        in the
        <span class="empty-tabs-tab-link" @click="gotoTab('slides')"
          >Slides tab →</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SearchEmptyState',
  props: {
    searchQuery: {
      type: String,
      required: true
    },
    types: {
      type: Object,
      default: () => {}
    },
    searchRequestProps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    slideCount: 0
  }),
  computed: {
    ...mapGetters(['activeFilters']),
    activeView() {
      return this.$route.query.view || 'all'
    },
    icon() {
      return require(`@/assets/icons/${
        this.activeView === 'bookmarks' ? 'bookmark' : 'search'
      }.svg`)
    },
    subtitle() {
      const viewMap = {
        bookmarks:
          'Click the "bookmark icon" on any search result to add a bookmark.',
        recent:
          'Seems like nothing has been created or changed during the last 90 days.'
      }
      if (this.activeView in viewMap) return viewMap[this.activeView]
      return this.searchEmpty
        ? ''
        : 'Please try rephrasing your search with keywords.'
    },
    title() {
      if (this.activeView === 'bookmarks') return 'No bookmarks yet'
      return this.searchEmpty
        ? "There's nothing here yet"
        : this.queryEmpty
        ? 'No results have been found'
        : `No results for <span class="bold">'${this.searchQuery}'</span>`
    },
    queryEmpty() {
      return this.searchQuery === ''
    },
    searchEmpty() {
      return this.queryEmpty && (this.activeFilters || []).length === 0
    },
    typeToTab() {
      return {
        presentation: 'Presentations',
        document: 'Docs',
        video: 'Video',
        sheet: 'Sheets'
      }
    }
  },
  async created() {
    if ((this.$route.params.tab || 'all') !== 'slides') {
      const slideResults = await this.getSearchResourcesPage({
        ...this.searchRequestProps,
        extraParams: {
          ...(this.searchRequestProps.extraParams || {}),
          page_size: 1
        },
        resourceType: 'slides'
      })
      this.slideCount = slideResults?.response?.data?.count || 0
    }
  },
  methods: {
    ...mapActions(['getSearchResourcesPage']),
    gotoTab(type) {
      const typeToTabname = {
        presentation: 'presentations',
        document: 'documents',
        video: 'videos',
        sheet: 'sheets',
        slides: 'slides'
      }
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          tab: typeToTabname[type]
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.empty {
  margin-top: 20vh;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &-icon {
    width: 4rem;
    padding: 0.5rem;
    background: #e9ebed;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    aspect-ratio: 1;
    object-fit: contain;
  }

  &-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  &-subtitle {
    color: #60666b;
    margin-bottom: 0.5rem;
  }

  &-tabs {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    padding: 1.5rem 0;
    align-items: center;

    &-header {
      color: #60666b;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    &-tab {
      color: #60666b;
      padding-left: 1rem;

      &-link {
        color: $primary;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.bold {
  font-weight: 600;
}
</style>
