var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "discover", attrs: { id: "slidesblock" } }, [
    _c(
      "p",
      {
        staticClass: "header semi-bold xs mb-4 row",
        attrs: { id: "slidesblocktitle" }
      },
      [
        _c("img", {
          staticClass: "header-img mr-3",
          attrs: { src: require("@/assets/icons/slides.svg"), alt: "" }
        }),
        _vm._v(" " + _vm._s(_vm.header) + " ")
      ]
    ),
    !_vm.allImagesLoaded
      ? _c(
          "div",
          { staticClass: "discover-loading row full-width" },
          _vm._l(3, function(el) {
            return _c("b-skeleton", { key: el, attrs: { height: "100%" } })
          }),
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "discover-slides" },
      _vm._l(_vm.slideData.subresources, function(slide, idx) {
        return _c("SlideVisualisation", {
          key: slide.uuid,
          class: { hidden: !_vm.allImagesLoaded },
          attrs: {
            subresource: slide,
            index: idx,
            "loaded-resources": _vm.imagesLoaded + _vm.imagesErrored,
            "total-resources": _vm.pageSize,
            "search-id": _vm.searchId,
            rank: idx + 1,
            location: "slidesblock"
          },
          on: {
            load: function($event) {
              _vm.imagesLoaded += 1
            },
            cantLoad: function($event) {
              _vm.imagesErrored += 1
            }
          }
        })
      }),
      1
    ),
    _c(
      "div",
      {
        staticClass: "discover-more link mt-4",
        on: { click: _vm.showMoreSlides }
      },
      [
        _vm._m(0),
        _c("img", {
          staticClass: "discover-more-img",
          attrs: { src: require("@/assets/icons/arrow-left.svg"), alt: "" }
        })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "link blue" }, [
      _vm._v("See more "),
      _c("span", { staticClass: "link bold" }, [_vm._v("slides")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }