











































































































































import LoadingMixin from '@c/mixins/LoadingMixin'
import Resource, {
  CARD,
  getLink,
  LIST,
  TILE,
  VISUALISATION_TYPES,
  VISUALISATION_TYPES_ENUM
} from '@c/models/Resource'
import User from '@c/models/User'
import Workspace from '@c/models/Workspace'
import ResourceGridLoading from '@c/shared/molecules/loading/resources/ResourceGridLoading.vue'
import ResourceVisualisation from '@c/shared/molecules/object-visualisations/resource/ResourceVisualisation.vue'
import { PropType } from 'vue'
// @ts-ignore
import infiniteScroll from 'vue-infinite-scroll'
// @ts-ignore
import { eventContext, sendBatchViewEvent } from '@/services/feedbackService'
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import VueFlexWaterfall from 'vue-flex-waterfall'
import { mapActions, mapGetters } from 'vuex'
import SlideVisualisation from './SlideVisualisation.vue'
import SnippetVisualisation from './SnippetVisualisation.vue'
import ResourceModal from './subcomponents/ResourceModal.vue'
import SheetsDiscover from './subcomponents/SheetsDiscover.vue'
import SlidesDiscover from './subcomponents/SlidesDiscover.vue'
import UserVisualisation from './UserVisualisation.vue'
import VideosDiscover from './subcomponents/VideosDiscover.vue'
import ResourceCard from './ResourceCard.vue'

const containerClassMap = {
  [CARD]: {},
  [LIST]: {
    'is-full': true,
    'mb-4': true,
    'u-column': true
  },
  [VISUALISATION_TYPES_ENUM.LIST_DENSE]: {
    'is-full': true,
    'mb-2': true,
    'u-column': true
  },
  [TILE]: {
    'u-column': true
  },
  [VISUALISATION_TYPES_ENUM.SLIDE]: {
    slide: true
  },
  [VISUALISATION_TYPES_ENUM.VIDEO]: {
    video: true
  }
}

export default LoadingMixin.extend({
  name: 'ResourceGrid',

  components: {
    ResourceVisualisation,
    ResourceGridLoading,
    SlideVisualisation,
    SnippetVisualisation,
    VueFlexWaterfall,
    ResourceModal,
    SlidesDiscover,
    UserVisualisation,
    VideosDiscover,
    SheetsDiscover,
    ResourceCard
  },

  directives: { infiniteScroll },

  props: {
    allLoaded: {
      type: Boolean,
      default: false
    },

    resources: {
      type: Array as PropType<Resource[]>,
      default: function (): Resource[] {
        return []
      }
    },

    visualisationType: {
      type: String,
      default: CARD,
      validator: function (value: VISUALISATION_TYPES_ENUM) {
        return VISUALISATION_TYPES.includes(value)
      }
    },

    customClasses: {
      type: [String, Object],
      default: undefined
    },

    customProps: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean as () => boolean,
      default: false
    },

    workspaces: {
      type: Array as () => Workspace[],
      default: function (): Workspace[] {
        return []
      }
    },

    currentUser: {
      type: Object as () => User,
      default: undefined
    },

    usesPlaceholders: {
      type: Boolean,
      default: true
    },

    gridClassOverride: {
      type: String,
      default: undefined
    },

    showTopics: {
      type: Boolean,
      default: false
    },

    totalResourceAmount: {
      type: Number,
      default: -1
    },

    sort: {
      type: String,
      required: true
    },

    showHeader: {
      type: Boolean,
      default: false
    },

    loadingFilters: {
      type: Boolean,
      default: false
    },

    extractedEntities: {
      type: Object,
      default: () => {}
    },
    searchRequestProps: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hasShownSlideNavigationToast: false,
      hideSlidesDiscover: false,
      hideVideosDiscover: false,
      hideSheetsDiscover: false,
      visualisationElements: [],
      scrollTimer: undefined,
      scrollIndex: 0,
      maxScrollY: 0,
      imagesLoaded: 0,
      initialImagesLoad: true,
      discoverBlocksCompleted: 0,
      discoverBlocksTriggered: false
    }
  },
  computed: {
    ...mapGetters(['downloadFilesEnabled']),
    visualisationTypes() {
      return VISUALISATION_TYPES_ENUM
    },
    loadingOrDone() {
      return this.allLoaded || this.loading
    },

    containerClasses() {
      return containerClassMap
    },

    loadingContainer() {
      return this.$refs.loadingContainer?.$el
    },

    gridClass(): string {
      if (this.gridClassOverride) {
        return this.gridClassOverride
      }
      if (
        [
          VISUALISATION_TYPES_ENUM.CARD as string,
          VISUALISATION_TYPES_ENUM.TILE as string
        ].includes(this.visualisationType)
      )
        return `resource-grid`
      if (
        [
          VISUALISATION_TYPES_ENUM.SLIDE as string,
          VISUALISATION_TYPES_ENUM.VIDEO as string,
          VISUALISATION_TYPES_ENUM.USER_CARD as string
        ].includes(this.visualisationType)
      )
        return 'slide-grid'
      if (VISUALISATION_TYPES_ENUM.SNIPPET === this.visualisationType)
        return 'snippet-grid'
      return ''
    },
    masonryCols() {
      return {
        [VISUALISATION_TYPES_ENUM.SNIPPET]: 2,
        [VISUALISATION_TYPES_ENUM.USER_CARD]: 4
      }[this.visualisationType]
    },
    masonryBreaks() {
      return {
        [VISUALISATION_TYPES_ENUM.SNIPPET]: {
          350: 1,
          500: 2
        },
        [VISUALISATION_TYPES_ENUM.USER_CARD]: {
          350: 1,
          500: 2,
          750: 3,
          1000: 4
        }
      }[this.visualisationType]
    },
    isAllResults() {
      return !this.$route.params.tab
    },
    allImagesLoaded() {
      return this.imagesLoaded === this.resources.length
    }
  },
  mounted() {
    this.$root.$on('opened-detail', this.showSlideNavigationToast)
    this.visualisationElements = Array.from(
      document.getElementsByClassName('data-visualisation-scroll')
    )
    if ((this.resources?.length || 5) < 5) {
      this.discoverBlocksTriggered = true
    }
    if (
      ![this.visualisationTypes.SLIDE, this.visualisationTypes.VIDEO].includes(
        this.visualisationType
      )
    )
      this.initialViewEvent()
  },
  destroyed() {
    document
      .getElementById('scroll-container')
      ?.removeEventListener('scroll', this.scrollEventListener)
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.$nextTick(
          () =>
            (this.visualisationElements = Array.from(
              document.getElementsByClassName('data-visualisation-scroll')
            ))
        )
        if (this.visualisationType !== this.visualisationTypes.SLIDE)
          this.sendViewEvent()
      }
    },
    allImagesLoaded(newVal) {
      if (newVal) {
        if (this.initialImagesLoad) {
          this.initialViewEvent()
          this.initialImagesLoad = false
          return
        }
        this.sendViewEvent()
      }
    },
    discoverBlocksCompleted(newVal) {
      if (newVal === 3) {
        this.setSearchEnrichmentContext(undefined)
      }
    }
  },
  methods: {
    ...mapActions(['setSendingEvent', 'setSearchEnrichmentContext']),
    handleInfiniteScroll() {
      if (
        [this.visualisationTypes.SLIDE, this.visualisationTypes.VIDEO].includes(
          this.visualisationType
        ) &&
        !this.allImagesLoaded
      )
        return
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.scroll
      })
      this.$console.debug('Loading more resources')
      this.$emit('loadMore')
    },
    scrollEventListener(event) {
      if (!this.discoverBlocksTriggered && event.target.scrollTop > 150)
        this.discoverBlocksTriggered = true
      if (this.scrollTimer) clearTimeout(this.scrollTimer)
      this.setSendingEvent(true)
      this.scrollTimer = setTimeout(() => {
        this.sendViewEvent()
      }, 500)
    },
    initialViewEvent() {
      this.maxScrollY =
        this.visualisationElements[0].getBoundingClientRect().y +
        this.visualisationElements[0].getBoundingClientRect().height
      document
        .getElementById('scroll-container')
        ?.addEventListener('scroll', this.scrollEventListener)
      const { newIndex, newElement } = this.getNewScrollIndex()
      sendBatchViewEvent(this.$route.params.workspace_id, {
        ids: this.resources.slice(0, newIndex + 1).map((res, idx) =>
          this.visualisationType === this.visualisationTypes.SLIDE
            ? {
                subresource_trace_id: res.trace_id,
                resource_trace_id: res.references[0].resource.trace_id,
                rank: idx + 1
              }
            : {
                resource_trace_id: res.trace_id || res.uuid,
                rank: idx + 1
              }
        ),
        tab: this.$route.params.tab || 'all',
        context:
          this.visualisationType === this.visualisationTypes.SLIDE
            ? eventContext.subresource
            : eventContext.resource
      })
      this.scrollIndex = newIndex
      this.maxScrollY = newElement.y + newElement.height
    },
    sendViewEvent() {
      if (
        this.visualisationElements[this.scrollIndex].getBoundingClientRect().y >
        this.maxScrollY
      ) {
        this.setSendingEvent(false)
        return
      }
      const { newIndex, newElement } = this.getNewScrollIndex()
      if (newIndex === this.scrollIndex) {
        this.setSendingEvent(false)
        return
      }
      const sliceStart = this.scrollIndex + 1
      sendBatchViewEvent(
        this.$route.params.workspace_id,
        {
          ids: this.resources.slice(sliceStart, newIndex + 1).map((res, idx) =>
            this.visualisationType === this.visualisationTypes.SLIDE
              ? {
                  subresource_trace_id: res.trace_id,
                  resource_trace_id: res.references[0].resource.trace_id,
                  rank: sliceStart + idx + 1
                }
              : {
                  resource_trace_id: res.trace_id || res.uuid,
                  rank: sliceStart + idx + 1
                }
          ),
          tab: this.$route.params.tab || 'all',
          context:
            this.visualisationType === this.visualisationTypes.SLIDE
              ? eventContext.subresource
              : eventContext.resource
        },
        () => this.setSendingEvent(false)
      )
      this.scrollIndex = newIndex
      this.maxScrollY = newElement.y + newElement.height
    },
    getNewScrollIndex() {
      let newIndex = this.scrollIndex
      let newElement =
        this.visualisationElements[newIndex].getBoundingClientRect()
      while (newElement.y + newElement.height < window.innerHeight) {
        if (newIndex + 1 === this.visualisationElements.length) break
        newIndex += 1
        newElement =
          this.visualisationElements[newIndex].getBoundingClientRect()
      }
      if (newIndex + 1 < this.visualisationElements.length && newIndex !== 0) {
        newIndex -= 1
        newElement =
          this.visualisationElements[newIndex].getBoundingClientRect()
      }
      return { newIndex, newElement }
    },
    showSlideNavigationToast() {
      if (this.hasShownSlideNavigationToast) return
      setTimeout(() => {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            '← You can use the left and right arrow keys to navigate through slide details. →',
          position: 'is-bottom',
          type: 'is-white'
        })
        this.hasShownSlideNavigationToast = true
      }, 1000)
    },
    getLink(resource: Resource): string {
      return getLink(resource)
    },
    createProps(resource: any): any {
      if (
        [
          VISUALISATION_TYPES_ENUM.SLIDE as string,
          VISUALISATION_TYPES_ENUM.SNIPPET as string,
          VISUALISATION_TYPES_ENUM.VIDEO as string
        ].includes(this.visualisationType)
      )
        return
      return {
        link: (this as any).getLink(resource),
        title: resource.title,
        uuid: resource.uuid,
        resourceIntegration: resource.integrationfile,
        blobMimetype: resource.integrationfile?.mimetype,
        savesActive: false,
        viewsActive: false,
        image: resource.subresources?.[0].preview,
        description: resource.description,
        workspaces: this.workspaces,
        currentUser: this.currentUser,
        highlights: resource.search_highlights,
        subresources: resource?.subresources || [],
        contentClass: resource.content_class,
        resourceType: resource.type,
        organisations: resource.organisations,
        industries: resource.industries,
        topics: resource.topics,
        activity: resource.integrationfile.activity,
        similarPages: resource.similar_pages,
        label: resource.label,
        missingWords: resource.missing_words || [],
        bookmark: resource.bookmark,
        traceId: resource.trace_id,
        highlight: resource.highlight
      }
    },
    gotoResource(resource: any) {
      // @ts-ignore
      const link = (this as any).getLink(resource)
      this.$console.log('Going to', link)
      this.$resource.goToResource(link)
    }
  }
})
