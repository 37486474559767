var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHovering,
            expression: "isHovering"
          }
        ],
        staticClass: "info",
        on: {
          mouseenter: _vm.onInfoMouseEnter,
          mouseleave: _vm.onInfoMouseLeave
        }
      },
      [
        _c("b-icon", {
          attrs: {
            type: "is-white",
            size: "is-small",
            icon: "information-variant"
          }
        }),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.references
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showInfo,
                      expression: "showInfo"
                    }
                  ],
                  staticClass: "card info-card pa-4",
                  class: _vm.cardPositionClass,
                  style:
                    "min-width: " +
                    _vm.width +
                    "px; max-width:: " +
                    _vm.width +
                    "px;width: " +
                    _vm.width +
                    "px",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c("h1", { staticClass: "mb-3" }, [
                    _vm._v(
                      " Appears in " +
                        _vm._s(_vm.totalReferences) +
                        " " +
                        _vm._s(
                          _vm.totalReferences === 1 ? "document" : "documents"
                        ) +
                        " "
                    )
                  ]),
                  _vm._l(_vm.referencesShown, function(reference, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        { staticClass: "reference mt-2" },
                        [
                          _c("DocumentTag", {
                            staticClass: "u-column is-narrow mr-3",
                            attrs: {
                              mimetype:
                                reference.resource.integrationfile.mimetype
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onResourceRedirect(
                                    reference.resource
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(reference.resource.title) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  _vm.totalReferences > 3
                    ? _c(
                        "div",
                        {
                          staticClass: "showmore link blue mb-4 ml-3",
                          on: {
                            click: function($event) {
                              _vm.showMore = !_vm.showMore
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.showMore ? "Show less" : "Show more") +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.showTags
                    ? _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("h1", [_vm._v(_vm._s(_vm.tagsHeader))]),
                          _c("ResourceTagList", {
                            staticClass: "mt-3",
                            attrs: { tags: _vm.tags }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }