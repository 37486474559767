<template>
  <component :is="clickable ? 'router-link' : 'div'" :to="profileRoute">
    <div class="user" @click="goToProfile()">
      <div class="user-header">
        <img
          v-if="userAvatar && !avatarError"
          :src="userAvatar"
          alt=""
          class="user-header-img"
          @error="avatarError = true"
        />
        <div v-else class="user-header-img" :style="`background: ${userColor};`">
          {{ userInitials }}
        </div>
        <div class="user-header-info">
          <div class="user-header-info-name">
            {{ userFullName }}
          </div>
          <div v-if="userRole" class="user-header-info-title">
            {{ userRole }}
          </div>
        </div>
        <b-tooltip label="Show CV" position="is-top" type="is-dark">
          <Button
            v-if="user.cv_ids && user.cv_ids.length"
            icon="document"
            type="white"
            class="user-header-cv"
            @click.native.stop="openUserCV"
          />
        </b-tooltip>
      </div>
      <div v-if="showBody" class="user-body">
        <div
          v-if="visibleTopics && visibleTopics.length && showTopics"
          class="user-body-block"
        >
          <p class="user-body-block-title">Core expertise</p>
          <div class="user-body-block-pills">
            <Tag
              v-for="topic in visibleTopics"
              :key="topic.uuid"
              :text="topic.name"
              type="white"
              size="xs"
            />
          </div>
        </div>
        <div
          v-if="visibleOrganisations && showOrganisations"
          class="user-body-block"
        >
          <p class="user-body-block-title">Works on</p>
          <p class="user-body-block-list">
            {{ visibleOrganisations }}
          </p>
        </div>
        <div v-if="visibleIndustries && showIndustries" class="user-body-block">
          <p class="user-body-block-title">Industries</p>
          <p class="user-body-block-list">
            {{ visibleIndustries }}
          </p>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import {
  eventContext,
  eventPage,
  sendInspectEvent
} from '@/services/feedbackService'
import { Mutations } from '@/store'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'
import { Mimetypes, mimetypeToType } from '@c/mimetypes'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'UserVisualisation',
  components: { Button, Tag },
  props: {
    user: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    },
    clickable: {
      type: Boolean,
      default: true
    },
    showTopics: {
      type: Boolean,
      default: true
    },
    showOrganisations: {
      type: Boolean,
      default: true
    },
    showIndustries: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    avatarError: false,
    userCVResources: []
  }),
  computed: {
    showBody() {
      return (
        (this.showTopics && this.visibleTopics?.length) ||
        (this.showIndustries && this.visibleIndustries) ||
        (this.showOrganisations && this.visibleOrganisations)
      )
    },
    userAvatar() {
      return this.user?.user?.avatar
    },
    userRole() {
      return this.user?.role
    },
    userNameObject() {
      return this.user?.user?.first_name
        ? this.user.user
        : this.user.integrationusers.find((iu) => iu.first_name)
    },
    userFullName() {
      return this.$umodel.full_name(this.userNameObject)
    },
    userInitials() {
      return this.$umodel.initials(this.userNameObject)
    },
    userColor() {
      return this.$umodel.user_color(this.user)
    },
    topics() {
      return this.user.topics
    },
    organisations() {
      return this.user.organisations
    },
    industries() {
      return this.user.industries
    },
    visibleTopics() {
      return this.topics.slice(0, 5)
    },
    visibleOrganisations() {
      return this.organisations
        .map((el) => el.name)
        .slice(0, 5)
        .join(', ')
    },
    visibleIndustries() {
      return this.industries
        .map((el) => el.name)
        .slice(0, 5)
        .join(', ')
    },
    profileRoute() {
      if (!this.clickable) return
      return {
        name: 'member-profile',
        params: {
          workspace_id: this.$route.params.workspace_id,
          member_id: this.user.uuid
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'restartBreadCrumbs',
      'getWorkspaceResourceDetailBulk',
      'setResourceModal'
    ]),
    ...mapMutations([Mutations.UPSERT_RESOURCES]),
    goToProfile() {
      if (!this.clickable) return
      sendInspectEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.user.uuid,
        context: eventContext.resource,
        rank: this.rank,
        comment: 'opening member profile from search results',
        page: eventPage.search_results,
        tab: this.$route.params.tab || 'all'
      })
      this.restartBreadCrumbs({ ...this.$route })
    },
    async openUserCV() {
      let cvs = []
      if (this.userCVResources?.length) {
        cvs = this.userCVResources
      } else {
        cvs = await this.getWorkspaceResourceDetailBulk({
          workspace_id: this.$route.params.workspace_id,
          resource_ids: this.user.cv_ids,
          act_as: this.$route.query.act_as
        })
        this.UPSERT_RESOURCES(cvs)
        this.userCVResources = cvs
      }
      const cv = cvs.reduce((prev, cv, idx) => {
        if (idx === 0) return cv
        return new Date(
          cv?.integrationfile?.external_modified_date ||
            cv?.integrationfile?.external_created_date
        ) >
          new Date(
            prev?.integrationfile?.external_modified_date ||
              prev?.integrationfile?.external_created_date
          )
          ? cv
          : prev
      }, {})
      this.setResourceModal({
        resource_id: cv.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: 1,
          selectable: this.selectableResource(cv),
          scrollTo: '',
          similarPages: [],
          rank: this.rank,
          resourceType: cv.type,
          resourceTraceId: cv.trace_id
        },
        buefy_modal_props: {}
      })
    },
    selectableResource(resource) {
      return [Mimetypes.PPTX, Mimetypes.PPT, Mimetypes.GOOGLE_SLIDES].includes(
        mimetypeToType[resource.integrationfile.mimetype]
      )
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(#000, 8%);
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  cursor: pointer;
  min-height: 100%;
  transition: all ease 200ms;

  &:hover {
    border: 1px solid rgba(#000, 30%);
    box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;

    &-img {
      min-width: 3rem;
      max-width: 3rem;
      min-height: 3rem;
      max-height: 3rem;
      border-radius: 100%;
      border: 1px solid rgba(#000, 8%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: 700;
      color: #fff;
      line-height: 0;
    }

    &-info {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;
      width: 100%;
      max-width: 100%;

      &-name {
        font-size: 1.125rem;
        font-weight: 600;
      }

      &-title {
        font-size: 0.9rem;
        color: #60666b;
      }
    }

    &-cv {
      margin-left: auto;
    }
  }

  &-body {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;

      &-title {
        font-size: 0.8rem;
        color: #60666b;
      }

      &-list {
        font-size: 0.9rem;
        display: inline;
      }

      &-pills {
        display: inline-flex;
        flex-flow: row wrap;
        gap: 0.3rem 0.5rem;

        &-item {
          display: inline;
          padding: 0.3rem 0.75rem;
          border-radius: 6px;
          background: #f1f2f3;
          color: #8f9399;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
