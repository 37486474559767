var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu"
        }
      ],
      staticClass: "assist"
    },
    [
      _vm._t("trigger", function() {
        return [
          _c(
            "b-tooltip",
            {
              staticClass: "assist-trigger",
              attrs: { label: "AI Assist", position: "is-top", type: "is-dark" }
            },
            [
              _c(
                "p",
                {
                  staticClass: "assist-trigger-wrapper",
                  on: {
                    click: function($event) {
                      return _vm.toggleMenu()
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "assist-trigger-icon",
                    attrs: {
                      src: require("@/assets/icons/lightning" +
                        (_vm.similarPages.length > 0 ? "-filled" : "") +
                        ".svg"),
                      alt: ""
                    }
                  }),
                  _vm.similarPages.length > 0
                    ? _c("span", { staticClass: "assist-trigger-count" }, [
                        _vm._v(_vm._s(_vm.similarPages.length))
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.menuOpen,
                expression: "menuOpen"
              }
            ],
            staticClass: "assist-menu-wrapper"
          },
          [
            _c("div", { staticClass: "assist-menu" }, [
              _c(
                "div",
                {
                  staticClass: "assist-menu-item",
                  class: { disabled: _vm.similarPages.length === 0 },
                  on: {
                    click: function($event) {
                      return _vm.openSimilarDocuments()
                    }
                  }
                },
                [
                  _c("p", { staticClass: "assist-menu-item-info" }, [
                    _c("img", {
                      staticClass: "assist-menu-item-icon",
                      attrs: {
                        src: require("@c/assets/icons/similar.svg"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "assist-menu-item-text" }, [
                      _vm._v("Similar documents")
                    ])
                  ]),
                  _c("span", { staticClass: "assist-menu-item-count" }, [
                    _vm._v(_vm._s(_vm.similarPages.length))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "assist-menu-item",
                  on: {
                    click: function($event) {
                      return _vm.openSummarize()
                    }
                  }
                },
                [
                  _c("p", { staticClass: "assist-menu-item-info" }, [
                    _c("img", {
                      staticClass: "assist-menu-item-icon",
                      attrs: {
                        src: require("@c/assets/icons/notepad.svg"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "assist-menu-item-text" }, [
                      _vm._v("Summarize")
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }