import Vue from 'vue'

export default Vue.extend({
  methods: {
    decorateText(highlight: string, splitStart = '<em>', splitEnd = '</em>') {
      const boldStarts = highlight.indicesOf(splitStart)
      const boldEnds = highlight.indicesOf(splitEnd)
      const splitValues = [
        ...new Set([0, highlight.length, ...boldEnds, ...boldStarts])
      ]
      splitValues.sort((x, y) => x - y)
      const decoratedValues = []
      for (let i = 0; i < splitValues.length - 1; i++) {
        const splitValue = splitValues[i]
        const bold = boldStarts.indexOf(splitValue) > -1
        decoratedValues.push({
          text: highlight
            .slice(splitValue, splitValues[i + 1])
            .replace(splitStart, '')
            .replace(splitEnd, ''),
          bold
        })
      }
      return decoratedValues
    }
  }
})
