<template>
  <div v-if="missingWords.length > 0" class="missing-words-container">
    <div v-if="missingWords.length === 1">
      Missing word: <span class="missing-words">{{ missingWords[0] }}</span> |
      Must include:
      <span class="must-include" @click="mustIncludeClick">{{
        missingWords[0]
      }}</span>
    </div>
    <div v-else>
      Missing words: <span class="missing-words">{{ joinedMissingWords }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MissingWords',
  props: {
    missingWords: {
      type: Array,
      default: () => []
    },
    resourceTraceId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['lastSearchId']),
    joinedMissingWords() {
      return this.missingWords.join(' ')
    }
  },
  methods: {
    async mustIncludeClick() {
      // TODO: add method for passing search trigger to search call here
      const searchQuery = this.$route.query.query.replace(
        this.missingWords[0],
        `"${this.missingWords[0]}"`
      )
      this.$router.push({
        path: this.$route.path,
        query: {
          query: searchQuery
        },
        params: {
          ...this.$route.params,
          workspace_id: this.$route.params.workspace_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.missing-words-container {
  padding: 6px 8px 4px 8px;
  width: fit-content;
  border: solid 1px #dddfe2;
  border-radius: 100px;
  white-space: nowrap;
}
.missing-words {
  text-decoration: line-through;
}
.must-include {
  color: $blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
