var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { width: "100%", height: "100%" },
      attrs: {
        width: "323",
        height: "191",
        viewBox: "0 10 323 173",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M0 4.85001C0 2.17143 2.17142 0 4.85 0H318.15C320.829 0 323 2.17142 323 4.85V191H0V4.85001Z",
          fill: "#F7F7FC"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M169.132 48.0002H127V143.244H196.999V75.8667L169.132 48.0002Z",
          fill: "#2684FF"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "161.915",
          cy: "101.915",
          r: "22.7537",
          fill: "white",
          stroke: "#E7E7E7",
          "stroke-width": "0.322748"
        }
      }),
      _c("path", {
        attrs: {
          d: "M168.886 75.5405V48L197 75.5405H168.886Z",
          fill: "#0048A6"
        }
      }),
      _c("image", {
        attrs: {
          height: "32",
          width: "32",
          x: "146px",
          y: "85px",
          href: _vm.integrationImage
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }