<template>
  <div>
    <SearchResults
      :default-type="landingType || foundType"
      :search-query="searchQuery"
      :results-resources="resourceResults || []"
      :amount-results-resources="paginatorDataCount"
      :loading-resources="resourcesLoading"
      :loading-filters="settingUpPaginator"
      :workspaces="populatedWorkspaces"
      :all-loaded="isDone"
      :current-user="currentUser"
      :loading-secondary-data="settingUpPaginator"
      :filter-options="filterBlocks"
      :active-document-type="[]"
      :resource-types="facets.resourceTypes"
      :has-secondary="true"
      :sort="secondaryFilters.currentSortType"
      :total-resources="totalResourceAmount"
      :extracted-entities="facets.extractedEntities"
      :loading-error="loadingError"
      :top-filters="[]"
      :search-request-props="searchRequestProps"
      :types="aggregations ? aggregations.types || {} : {}"
      @filterClicked="(x) => activateFilter(x)"
      @optionClicked="(option) => activateFilter(option)"
      @clearFilters="clearFilters"
      @clearFiltersByType="clearFiltersByType"
      @saveFilters="() => setFilterInfo()"
      @sortingChanged="(x) => (secondaryFilters.currentSortType = x)"
      @loadMoreResources="loadMoreResources"
      @topicChanged="onTopicChanged"
    >
      <template v-slot:top-blocks>
        <PeopleBlock
          v-show="searchQuery"
          class="mt-4 mb-5"
          :members="members"
          :loading="resourcesLoading"
        />
      </template>
    </SearchResults>
    <CustomSlideCreator v-if="showCollectSlides" />
  </div>
</template>

<script>
import Console from '@/console'
import HydrationMixin from '@/mixins/HydrationMixin'
import { Mutations } from '@/store'
import SearchResults from '@c/features/search-results/pages/SearchResults.vue'
import ResourceGridMixin from '@c/mixins/hydration/ResourceGridMixin'
import { SecondarySearchMixin } from '@c/mixins/hydration/SecondarySearchMixin'
import { SORT_TYPES_ENUM } from '@c/models/SortingFiltering'
import CustomSlideCreator from '@c/shared/molecules/object-visualisations/resource/subcomponents/CustomSlideCreator.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PeopleBlock from '../../../components/src/features/search-results/organisms/result-blocks/PeopleBlock.vue'

export default {
  name: 'SearchPageWrapper',

  components: {
    SearchResults,
    PeopleBlock,
    CustomSlideCreator
  },

  mixins: [HydrationMixin, ResourceGridMixin, SecondarySearchMixin],

  data() {
    return {
      extractedTopic: undefined,
      sourceFilterExpanded: undefined,
      resourceResults: [],
      seenResources: {}
    }
  },

  computed: {
    ...mapState({
      resourceTypeFilter: (state) => state.workspaces.resourceTypeFilter,
      labelRemovedName: (state) => state.labels.labelRemovedName,
      labelRemovedId: (state) => state.labels.labelRemovedId,
      labelSimilarSlidesName: (state) => state.labels.labelSimilarSlidesName,
      labelSimilarSlidesId: (state) => state.labels.labelSimilarSlidesId
    }),
    ...mapGetters([
      'resourceTypeFilter',
      'showCollectSlides',
      'workspaces',
      'qualityLabels',
      'deduplicationEnabled'
    ]),
    searchQuery() {
      return this.$route.query.query
    },

    workspaceId() {
      return this.$route?.params?.workspace_id
    },

    landingType() {
      return this.$route?.query?.type
    },

    foundType() {
      const amounts = [{ key: 'resource', amount: this.amountResources }]
      const typeWithResults = amounts.filter((x) => x.amount > 0)
      return typeWithResults.length > 0 ? typeWithResults[0].key : undefined
    },

    singularTopic() {
      if (this.extractedTopic) {
        return this.extractedTopic
      }
      return undefined
    },

    resourcesLoading() {
      return this.consumptionLoading
    },

    totalResourceAmount() {
      return this.paginatorDataCount
    },

    members() {
      return this.facets.experts && this.facets.experts.length
        ? this.facets.experts
        : []
    },
    pageSize() {
      const pageSizeMap = {
        slides: 28,
        snippets: 28,
        video: 28,
        people: 32
      }
      if (!(this.resourceTypeFilter in pageSizeMap)) return 10
      return pageSizeMap[this.resourceTypeFilter]
    },

    paginatorDataSize() {
      return this.paginatorData?.length || 0
    },

    filtersInQuery() {
      return this.$route.query
    },

    searchRequestProps() {
      let props = this.getRequestData(
        this.getExtraData() || {},
        this.getExtraParams() || {}
      )
      delete props.data.types
      const resourceExclusiveFacets = this.resourceFacets.filter(
        (facet) => !this.subresourceFacets.includes(facet)
      )
      const slideDiscoverDisabled = Object.keys(props.data).some((facet) =>
        resourceExclusiveFacets.includes(facet)
      )

      props.extraParams.included_facets = this.subresourceFacets.join(' ')
      return slideDiscoverDisabled ? {} : props
    }
  },

  watch: {
    async resourceTypeFilter() {
      this.resourceResults = []
      this.seenResources = {}
      this.setupPaginatorConsumer()
    },
    async searchQuery(newVal) {
      this.secondaryFilters.currentSortType = newVal
        ? SORT_TYPES_ENUM.RELEVANCE
        : SORT_TYPES_ENUM.MODIFIED_DATE
      this.resourceResults = []
      this.seenResources = {}
      this.resetSourcesAndDomains(false)
      await this.resetPaginatorConsumer()
    },

    filtersInQuery: {
      deep: true,
      async handler(newVal, oldVal) {
        if (newVal.query !== oldVal.query) return
        this.resourceResults = []
        this.seenResources = {}
        this.resetSourcesAndDomains(false)
        await this.resetPaginatorConsumer()
      }
    },

    async workspaceId(newVal) {
      this.resetSourcesAndDomains(false)
      this.resourceResults = []
      this.seenResources = {}
      await this.resetPaginatorConsumer()
      this.setCurrentWorkspace(newVal)
    },
    workspaces: {
      immediate: true,
      deep: true,
      handler() {
        this.setCurrentWorkspace(this.workspaceId)
      }
    },

    paginatorDataSize(newVal, oldVal) {
      if (!this.paginatorData) {
        this.resourceResults = []
        this.seenResources = {}
        return
      }
      const delta = newVal - oldVal
      const newData = this.paginatorData.slice(-delta)
      if (
        this.resourceTypeFilter === 'slides' ||
        this.resourceTypeFilter === 'snippets' ||
        this.resourceTypeFilter === 'people' ||
        !this.deduplicationEnabled
      ) {
        this.resourceResults.push(...newData)
        // sendBatchListEvent(this.$route.params.workspace_id, {
        //   ids: this.resourceResults.map((res, idx) => ({
        //     subresource_trace_id: res.trace_id,
        //     resource_trace_id: res.references[0].resource.trace_id,
        //     rank: idx
        //   })),
        //   tab: this.$route.params.tab || 'all',
        //   context: eventContext.subresource
        // })
        return
      }
      const dataToAdd = this.collapseIntersectedResources(newData)
      this.resourceResults.push(...dataToAdd)
      this.UPSERT_RESOURCES(this.resourceResults)
      // sendBatchListEvent(this.$route.params.workspace_id, {
      //   ids: this.resourceResults.map((res, idx) => ({
      //     resource_trace_id: res.trace_id,
      //     rank: idx
      //   })),
      //   tab: this.$route.params.tab || 'all',
      //   context: eventContext.resource
      // })
    },
    labelRemovedName(newValue) {
      Console.debug(`Removal of tags detected`, newValue, this.labelRemovedId)
      const resIdx = this.resourceResults.findIndex(
        (el) => el.uuid === this.labelRemovedId
      )
      this.resourceResults[resIdx].label = {}
    },
    labelSimilarSlidesName(newValue) {
      Console.debug(
        `Adding of tag detected`,
        newValue,
        this.labelSimilarSlidesId
      )
      if (newValue !== undefined) {
        const resIdx = this.resourceResults.findIndex(
          (el) => el.uuid === this.labelSimilarSlidesId
        )
        this.resourceResults[resIdx].label = {
          name: this.labelSimilarSlidesName,
          type: this.getLabelType(this.labelSimilarSlidesName)
        }
      }
    }
  },
  methods: {
    ...mapActions(['getUsersPaginator', 'setCurrentWorkspace']),
    ...mapMutations([
      Mutations.UPSERT_RESOURCES,
      Mutations.RESET_RESOURCES_STATE,
      Mutations.SET_ACTIVE_WORKSPACE
    ]),
    onTopicChanged(x) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, topic: x.map((y) => y.uuid).join(' ') }
      })
    },
    async loadMoreResources() {
      try {
        await this.loadPage()
      } catch (error) {
        if (error.message === 'Axios_timeout') {
          return this.$toast.danger(
            'Timeout error',
            "Looks like we couldn't retrieve any result in time. Please try again."
          )
        }
        this.$toast.error(error, 'getting your data.')
      }
    },
    collapseIntersectedResources(data) {
      /**
       * Online deduplication algorithm pseudocode:
       *
       * For each new resource _res_:
       * 1/ Promoted resources
       *  - Check if there are any resources labeled as Recommended in _res_.similar_resources or
       *  if _res_ itself is labeled as Recommended
       *  -> If so, immediately add those to the results list, continue to the next _res_
       * 2/ LSH Hashing
       *  - Check similar_resources & for each resource _sim_res_, compare its created date with _res_
       *   -> If _res_ is most recent:
       *    - _to_add_res_ = _res_
       *    - _to_add_res_.similar_pages = res.similar_resources
       *   -> If _sim_res_ most recent:
       *    - _to_add_res_ = _sim_res_
       *    - _to_add_res_.similar_pages = res.similar_resources - _sim_res_ + _res_
       * 3/ Intersections
       *  - Check intersected_resources & iterate over all _int_res_ that **have been processed before**
       *   -> If _int_res_ has a correlation > 0.3 with _to_add_res_ or all subresources of _int_res_ and _to_add_res_ are the same:
       *    - Compare their created dates
       *     -> If _int_res_ is most recent
       *      - add _to_add_res_° to _int_res_.similar_pages
       *     -> If _to_add_res_ is most recent
       *      - _to_add_res_.similar_pages = _int_res_.similar_pages + _int_res_
       *      - swap out _int_res_ with _to_add_res_ in processed results
       * **/
      const dataToAdd = []
      for (let resource of data) {
        resource.similar_pages = []
        const { promoted, res } = this.promotedDeduplication(resource)
        promoted.forEach((el) => {
          dataToAdd.push(el)
          this.seenResources[el.uuid] = el.subresources.map((el) => el.uuid)
        })
        if (!res) continue
        const resourceToAdd = this.lshDeduplication(res)
        const intersection = this.intersectionDeduplication(resourceToAdd)
        if (!intersection) {
          dataToAdd.push(resourceToAdd)
          this.seenResources[resourceToAdd.uuid] =
            resourceToAdd.subresources.map((el) => el.uuid)
          continue
        }
        const dataToAddIdx = dataToAdd.findIndex(
          (el) => el.uuid === intersection.resource_id
        )
        const intersectionInResults = dataToAddIdx === -1
        const intersectionResource = intersectionInResults
          ? this.resourceResults.find(
              (el) => el.uuid === intersection.resource_id
            )
          : dataToAdd[dataToAddIdx]
        const intersectionIsNewer =
          new Date(intersectionResource.integrationfile.external_created_date) >
            new Date(resourceToAdd.integrationfile.external_created_date) ||
          this.isPromoted(intersectionResource)
        const newResource = intersectionIsNewer
          ? {
              ...intersectionResource,
              similar_pages: [
                ...intersectionResource.similar_pages,
                ...resourceToAdd.similar_pages,
                {
                  duplicatedSubresourcesLength: Object.keys(
                    intersection.duplicated_subresources
                  ).length,
                  intersectionType: intersection.type,
                  resource: resourceToAdd
                }
              ]
            }
          : {
              ...resourceToAdd,
              similar_pages: [
                ...resourceToAdd.similar_pages,
                ...intersectionResource.similar_pages,
                {
                  duplicatedSubresourcesLength: Object.keys(
                    intersection.duplicated_subresources
                  ).length,
                  intersectionType: intersection.type,
                  resource: intersectionResource
                }
              ]
            }
        if (!intersectionIsNewer) {
          delete this.seenResources[intersectionResource.uuid]
          this.seenResources[newResource.uuid] = newResource.subresources.map(
            (el) => el.uuid
          )
        }
        if (intersectionInResults) {
          this.resourceResults[
            this.resourceResults.findIndex(
              (el) => el.uuid === intersection.resource_id
            )
          ] = newResource
        } else {
          dataToAdd[dataToAddIdx] = newResource
        }
      }
      return dataToAdd
    },
    promotedDeduplication(resource) {
      const toReturn = {
        promoted: [],
        res: undefined
      }
      const { similar_resources, ...ogResource } = resource
      const toCheck = [...similar_resources, ogResource]
      const hasPromoted = toCheck.some((el) => this.isPromoted(el))
      if (hasPromoted) {
        toReturn.promoted = this.isPromoted(ogResource)
          ? [
              {
                ...ogResource,
                similar_resources: similar_resources.filter(
                  (el) => !this.isPromoted(el)
                )
              },
              ...similar_resources.filter((el) => this.isPromoted(el))
            ]
          : similar_resources.filter((el) => this.isPromoted(el))
        if (!this.isPromoted(ogResource)) {
          toReturn.promoted[0].similar_resources = [
            ogResource,
            ...toReturn.promoted[0].similar_resources
          ]
        }
      } else {
        toReturn.res = resource
      }
      return toReturn
    },
    lshDeduplication(resource) {
      let resourceToAdd = { ...resource }
      const mostRecentSimilarRes = {
        ...resource.similar_resources.reduce(
          (prev, curr) =>
            new Date(prev.integrationfile.external_created_date) >
            new Date(curr.integrationfile.external_created_date)
              ? prev
              : curr,
          resource
        )
      }
      if (mostRecentSimilarRes.uuid === resource.uuid) {
        // Current resource is most recent, don't switch stacking order
        resourceToAdd.similar_pages = [
          ...(resource.similar_resources || []).map((res) => ({
            duplicatedSubresourcesLength: Object.keys(
              resource.intersected_resources.find(
                (el) => el.resource_id === res.uuid
              )?.duplicated_subresources || {}
            ).length,
            intersectionType: 'lsh',
            resource: res
          }))
        ]
      } else {
        const newSimilarPages = [
          ...(resource.similar_resources || [])
            .filter((res) => res.uuid !== mostRecentSimilarRes.uuid)
            .map((res) => ({
              duplicatedSubresourcesLength: Object.keys(
                mostRecentSimilarRes.intersected_resources.find(
                  (el) => el.resource_id === res.uuid
                )?.duplicated_subresources || {}
              ).length,
              intersectionType: 'lsh',
              resource: res
            })),
          {
            duplicatedSubresourcesLength: Object.keys(
              mostRecentSimilarRes.intersected_resources.find(
                (el) => el.resource_id === resource.uuid
              )?.duplicated_subresources || {}
            ).length,
            intersectionType: 'lsh',
            resource: {
              ...resource,
              similar_pages: []
            }
          }
        ]
        mostRecentSimilarRes.similar_pages = newSimilarPages
        resourceToAdd = mostRecentSimilarRes
      }
      return resourceToAdd
    },
    intersectionDeduplication(resource) {
      const intersectionIdx = resource.intersected_resources.findIndex(
        (el) => el.resource_id in this.seenResources
      )
      if (intersectionIdx === -1) return undefined
      const intersection = resource.intersected_resources[intersectionIdx]
      const mappedKeys = this.seenResources[intersection.resource_id].map(
        (subresource_id) => intersection.duplicated_subresources[subresource_id]
      )
      const delta = resource.subresources.filter(
        (sr) => !mappedKeys.includes(sr.uuid)
      ).length
      if (intersection.correlation < 0.3 && delta > 0) {
        return undefined
      }
      return intersection
    },
    isPromoted(resource) {
      return resource.label?.type === 'positive'
    },
    // RESOURCES
    getExtraData() {
      return {
        query: this.searchQuery,
        with_search_highlights: Boolean(this.searchQuery),
        extract_entities: true
      }
    },

    getExtraParams() {
      return {
        page_size: this.pageSize,
        workspace: this.workspaceId
      }
    },
    getLabelType(name) {
      return (
        this.qualityLabels.find(
          (el) => el.name.toLowerCase() === name.toLowerCase()
        )?.type || 'informative'
      )
    }
  }
}
</script>
<style scoped lang="scss">
.slide-up-enter-active {
  transition: all 0.15s ease-in-out;
}

.slide-up-leave-active {
  transition: all 0.15s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
  bottom: -106px;
}
</style>
