



















import Vue from 'vue'
import ResourceListItemLoading from './ResourceListItemLoading.vue'
import SubresourceItemLoading from './SubresourceItemLoading.vue'
import { VISUALISATION_TYPES_ENUM } from '@c/models/Resource'

export default Vue.extend({
  name: 'ResourceGridLoading',
  components: {
    ResourceListItemLoading,
    SubresourceItemLoading
  },
  props: {
    amount: {
      type: Number,
      default: 3
    },
    visualisationType: {
      type: String,
      required: true
    }
  },
  computed: {
    visualisationTypes() {
      return VISUALISATION_TYPES_ENUM
    }
  }
})
