var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BasicEmptyStateBlock", {
    staticClass: "mt-8",
    attrs: {
      title: "Error",
      subtitle:
        "Something went wrong while getting your data. Please try again or contact support.",
      icon: "alert-circle-outline"
    },
    scopedSlots: _vm._u([
      {
        key: "action",
        fn: function() {
          return undefined
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }