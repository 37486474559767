var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full grid grid-center" }, [
    _c(
      "div",
      { staticClass: "pa-5 state-block" },
      [
        _c(
          "div",
          {
            staticClass: "u-columns is-vcentered",
            staticStyle: { "flex-direction": "column" }
          },
          [
            _vm.icon
              ? _c(
                  "div",
                  { staticClass: "u-column is-narrow mb-5" },
                  [
                    _c("b-icon", {
                      attrs: {
                        size: "is-large",
                        "custom-class": "body body-text",
                        icon: _vm.icon
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.title
              ? _c("div", { staticClass: "u-column is-narrow" }, [
                  _c(
                    "p",
                    { staticClass: "body bold black xs has-text-centered" },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                ])
              : _vm._e(),
            _vm.subtitle
              ? _c("div", { staticClass: "u-column is-narrow" }, [
                  _c("p", { staticClass: "body xs has-text-centered" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm._t("action")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }