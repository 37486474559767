<template>
  <div>
    <div
      class="u-columns center"
      :class="{
        'u-columns': true,
        center: true,
        'is-clickable': showModal,
        selectable: showModal
      }"
      @click="$emit('click', $event)"
    >
      <UserAvatar
        :avatar="avatar"
        :width="size"
        :height="size"
        :orb="orb"
        class="mb-4"
      >
        <slot></slot>
        <template v-slot:orb>
          <slot name="orb"></slot>
        </template>
      </UserAvatar>
    </div>
    <slot name="title">
      <p class="body xs has-text-centered mb-2 user-title">{{ title }}</p>
    </slot>
    <slot name="subtitle">
      <p
        v-for="(sub, i) in subtitles"
        :key="i"
        class="body has-text-centered body-text xxs user-subtitle"
      >
        {{ sub }}
      </p>
    </slot>
  </div>
</template>

<script>
import UserAvatar from './UserAvatar'

export default {
  name: 'VerticalUserAvatar',
  components: { UserAvatar },

  props: {
    size: {
      type: Number,
      default: 32
    },

    avatar: {
      type: String,
      default: undefined
    },

    title: {
      type: String,
      default: undefined
    },

    subtitle: {
      type: [String, Array],
      default: () => []
    },

    orb: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subtitles() {
      return Array.isArray(this.subtitle) ? this.subtitle : [this.subtitle]
    },

    showModal() {
      return false
    }
  }
}
</script>

<style scoped>
.selectable:hover {
  background: rgba(221, 232, 226, 0.5);
}

.user-title,
.user-subtitle {
  font-weight: 900;
}
</style>
